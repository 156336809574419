import PropTypes from 'prop-types'

const ReadNowLink = ({
  className,
  route,
  children,
  page
}) => (
  <a
    className={className}
    data-action={page}
    href={route}
    rel='noopener noreferrer'
    target='_blank'
  >
    {children}
  </a>
)

export default ReadNowLink

ReadNowLink.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  page: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
}
