import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'

import {
  DIALOG_TYPES,
  PAGE_LEVEL_MESSAGE_TYPES,
  TOAST_TYPES
} from '@/constants/notificationTypes'

const determineType = (type, types) => Object.values(types).includes(type)

const isDialog = type => determineType(type, DIALOG_TYPES)
const isPageLevelMessage = type => determineType(type, PAGE_LEVEL_MESSAGE_TYPES)
const isToast = type => determineType(type, TOAST_TYPES)
const isBanner = type => (
  !isDialog(type) && !isPageLevelMessage(type) && !isToast(type)
)

const groupByType = ({ checker, messages }) => {
  const group = groupBy(messages, ({ type }) => checker(type)).true

  return group || []
}

const selectByType = ({ checker, messages }) => {
  const selection = find(messages, ({ type }) => checker(type))

  return selection || false
}

export const toastFlashMessagesSelector = createSelector(
  [state => state.flashMessages],
  messages => groupByType({ checker: isToast, messages })
)

export const hasToastFlashMessagesSelector = createSelector(
  [toastFlashMessagesSelector],
  toasts => toasts.length > 0
)

export const dialogFlashMessageSelector = createSelector(
  [state => state.flashMessages],
  messages => selectByType({ checker: isDialog, messages })
)

export const pageLevelMessageSelector = createSelector(
  [state => state.flashMessages],
  messages => selectByType({ checker: isPageLevelMessage, messages })
)

export const bannerFlashMessagesSelector = createSelector(
  [state => state.flashMessages],
  messages => groupByType({ checker: isBanner, messages })
)
