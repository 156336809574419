import CartLicenseInformation from '@/utils/factory/cartLicenseInformation'
import priceFactory from '@/utils/factory/price/priceFactory'

class CartItemVariant {
  constructor({
    daysLeftInLicense,
    isOnlineResource,
    isSubscription,
    variant: { duration, durationInDays, studentPrice }
  }) {

    const cartLicenseInformation = new CartLicenseInformation({
      daysLeftInLicense,
      duration,
      durationInDays,
      isOnlineResource,
      isSubscription
    })

    const price = priceFactory.createPrice('StudentPrice', { studentPrice })

    this.duration = cartLicenseInformation.duration.text
    this.expiration = cartLicenseInformation.expiration.text
    this.hasPrice = price.isShowing
    this.isPerpetual = cartLicenseInformation.duration.isPerpetual
    this.isShowingDuration = cartLicenseInformation.duration.isShowing
    this.isShowingExpiration = cartLicenseInformation.expiration.isShowing
    this.price = price.formattedAmount
  }
}

export default CartItemVariant
