import { useEffect, useState } from 'react'

import ReduxProvider from '@/components/ReduxProvider'
import SkeletonThemeWrapper from '@/components/SkeletonThemeWrapper'
import { translate } from '@/utils/translations'

import GiftModal from './GiftModal'
const GiftLink = () => {
  const [isShowing, setIsShowing] = useState(false)
  const [isShowingComponent, setIsShowingComponent] = useState(false)

  useEffect(() => {
    setIsShowingComponent(true)
  })

  return (
    <Choose>
      <When condition={isShowingComponent}>
        <div className='gift-link'>
          <button
            className='vst-btn--medium w-100 vst-btn--orange-outline'
            data-testid='gift-link-button'
            onClick={() => setIsShowing(true)}
            type='button'
          >
            <span>
              {translate('assets.show.purchase_as_gift.link')}
            </span>
          </button>
        </div>
        <GiftModal
          isShowing={isShowing}
          onChangeShow={setIsShowing}
        />
      </When>
      <Otherwise>
        <SkeletonThemeWrapper count={1} />
      </Otherwise>
    </Choose>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = () => (
  <ReduxProvider>
    <GiftLink />
  </ReduxProvider>
)
export default Component
