import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  bulkReps: null,
  isImportsTableHidden: true,
  isPending: false,
  newRecord: null,
  publisherUsers: null
}

const publisherUsers = createSlice({
  extraReducers,
  initialState,
  name: SLICES.publisherUsers,
  reducers
})

export const { actions } = publisherUsers
export default publisherUsers
