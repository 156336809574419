import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialStatuses = {
  isUpdating: false
}

export const initialState = {
  isEnabled: false,
  isShowing: false,
  status: initialStatuses
}

const miniCart = createSlice({
  extraReducers,
  initialState,
  name: SLICES.miniCart,
  reducers
})

export const { actions } = miniCart
export default miniCart
