import { useSelector } from 'react-redux'

import {
  isAddToCartDisabledSelector,
  isShowingProceedToCheckoutSelector
} from '@/selectors'

import ExpressCheckoutDialog from '../ExpressCheckoutDialog'
import AddToCartActionButton from './AddToCartActionButton'
import DisabledActionButton from './DisabledActionButton'
import ProceedToCheckoutButton from './ProceedToCheckoutButton'
const AddToCartButton = () => {
  const isAddToCartDisabled = useSelector(isAddToCartDisabledSelector)
  const hasProceedToCheckout = useSelector(isShowingProceedToCheckoutSelector)

  return (
    <Choose>
      <When condition={isAddToCartDisabled}>
        <DisabledActionButton />
      </When>
      <When condition={hasProceedToCheckout}>
        <ProceedToCheckoutButton />
      </When>
      <Otherwise>
        <AddToCartActionButton />
        <ExpressCheckoutDialog />
      </Otherwise>
    </Choose>
  )
}

export default AddToCartButton
