import { setFocusToStepHeader } from '@/components/StripeV2Checkout/helper'
import { TOAST_TYPES } from '@/constants/notificationTypes'
import { actions as flashActions } from '@/reducers/flashMessages'
import { isMiniCartEnabledSelector, taxAddressSelector } from '@/selectors'
import http from '@/utils/http'
import { routeFor } from '@/utils/router'
import { translate } from '@/utils/translations'

import lineItems from './index'

const { actions } = lineItems

export const fetch = () => dispatch => {
  dispatch(actions.fetchStart())

  return http.get(routeFor('apiLineItems')).then(
    data => dispatch(actions.fetchSuccess(data))
  ).catch(
    error => dispatch(actions.fetchFailure(error))
  )
}

export const destroy = ({ assetTitle, itemId, headerId }) =>
  (dispatch, state) => {
  const taxAddress = taxAddressSelector(state())
  dispatch(actions.destroyStart(itemId))
  dispatch(flashActions.clearFlashMessages())

  return http.delete(
    routeFor('apiLineItem', { id: itemId }),
    { taxAddress }
  ).then(
    data => {
      dispatch(actions.destroySuccess(data))

      if (data.notice) {
        dispatch(flashActions.setFlashMessages(
          [{ text: data.notice, type: TOAST_TYPES.notice }]
        ))
      } else if (assetTitle) {
        dispatch(flashActions.setFlashMessages(
          [{
            text: translate('carts.item_removed', { title: assetTitle }),
            type: TOAST_TYPES.success
          }]
        ))
      }
      setFocusToStepHeader(headerId)
    }
  ).catch(error => dispatch(actions.destroyFailure(error)))
}

export const create = params => (dispatch, state) => {
  const isMiniCartEnabled = isMiniCartEnabledSelector(state())

  dispatch(actions.createStart(params))

  return http.post(routeFor('apiLineItems'), params).then(
    data => {
      if(isMiniCartEnabled) {
        dispatch(actions.createSuccess(data))
      } else {
        window.location.assign(routeFor('cart'))
      }
    }
  ).catch(
    error => dispatch(actions.createFailure(error))
  )
}

export const update = (item, url) => (dispatch, state) => {
  const taxAddress = taxAddressSelector(state())
  const { id, quantity, variantId } = item
  dispatch(flashActions.clearFlashMessages())

  dispatch(actions.updateStart(item))

  return http.put(
    routeFor('apiLineItem', id),
    { quantity, taxAddress, variantId }
  ).then(
    data => {
      if (url) {
        window.location.assign(url)
      } else {
        dispatch(actions.updateSuccess(data))
      }
      if (data.notice) {
        dispatch(flashActions.setFlashMessages(
          [{ text: data.notice, type: TOAST_TYPES.notice }]
        ))
      }
    }
  ).catch(
    error => dispatch(actions.updateFailure(error))
  )
}
