import { formatMoney } from 'accounting-js'

import { SHARED_ACTIONS } from '@/reducers/utils/constants'

const CART_RECOMMENDATIONS = 'Cart Recommendations'
const CART_RECOMMENDED_PRODUCTS = 'Recommended Products Cart'
const CART_RECOMMENDED_PRODUCTS_ID = 'recommended_products_cart'
const PDP_RECOMMENDATIONS = 'PDP Recommendations'
const PDP_RECOMMENDED_PRODUCTS = 'Recommended Products PDP'
const PDP_RECOMMENDED_PRODUCTS_ID = 'recommended_products_PDP'
const ON_CART_RECOMMENDATIONS = 'onCartRecommendations'

const consentUpdate = ({ payload }) => ({
  event: "consentUpdate",
  ...payload
})

const event = ({ payload: { name, props } }) => ({
  ...props,
  event: name
})

const formattedIsbn = isbn => {
  if (isbn !== undefined) {
    return isbn.replace(/-/g, '')
  }
}

const formattedName = ({ canonicalIsbn, title }) => {
  const isbn = formattedIsbn(canonicalIsbn)

  return `${title} - ${isbn}`
}

const formattedPriceWithFlatTax = ({ currentRegion, studentPrice }) => {
  const { currencySymbol, currencyPrecision, taxMultiplier } = currentRegion
  const flatTaxMultiplier = parseFloat(taxMultiplier)
  const price = studentPrice * flatTaxMultiplier

  if (price > 0) {
    return formatMoney(
      price, { precision: currencyPrecision, symbol: currencySymbol }
    )
  }
}

const assetProps = asset => ({
  canonicalIsbn: asset.canonicalIsbn,
  gtin: asset.gtin,
  imprintName: asset.imprintName,
  kind: asset.kind,
  productImageUrl: asset.productImageUrl,
  productUrl: asset.productUrl,
  slug: asset.slug,
  storeFeedSku: asset.storeFeedSku,
  title: asset.title
})

const relatedAssetsProps = ({ assets, assetsOrder, listType }) => (
  assetsOrder.map((key, index) => {
    const asset = assets[key]
    const { canonicalIsbn, imprintName, kind, title, selectedVariant } = asset
    const { sku, studentPrice } = selectedVariant
    const name = formattedName({ canonicalIsbn, title })
    const id = formattedIsbn(canonicalIsbn)

    return ({
      brand: imprintName,
      category: kind,
      id: id,
      list: listType,
      name: name,
      position: index + 1,
      price: studentPrice,
      variant: sku
    })
  })
)

const itemProps = ({ asset, currentRegion, variant }) => {
  const { canonicalIsbn, imprintName, kind, title } = asset
  const { sku, studentPrice } = variant
  const name = formattedName({ canonicalIsbn, title })
  const id = formattedIsbn(canonicalIsbn)
  const priceTax = formattedPriceWithFlatTax({ currentRegion, studentPrice })

  return ({
    currency: currentRegion.storeCurrency,
    item_brand: imprintName,
    item_category: kind,
    item_id: id,
    item_name: name,
    item_variant: sku,
    price: studentPrice,
    priceTax: priceTax
  })
}

const cartItemsProps = ({ currentRegion, items }) => (
  Object.values(items).map(item => {
    const { asset, quantity, variant } = item
    return ({
      ...itemProps({ asset, currentRegion, variant }),
      quantity: quantity
    })
  })
)

const recommendedItemProps = (
  { asset, currentRegion, list, position, variant }
) => {
  const { listId, listName } = list

  return ({
    ...itemProps({ asset, currentRegion, variant }),
    index: position,
    item_list_id: listId,
    item_list_name: listName
  })
}

const recommendedAssetsProps = (
  { assets, assetsOrder, currentRegion, list }
) => (
  assetsOrder.map((key, position) => {
    const asset = assets[key]
    const { selectedVariant } = asset

    return ({
      ...recommendedItemProps({
        asset,
        currentRegion,
        list,
        position,
        variant: selectedVariant
      })
    })
  })
)

const itemFromCartProps = ({ asset, currentRegion, quantity, variant }) => ({
  asset: assetProps(asset),
  lineItem: { quantity },
  region: {
    currencySymbol: currentRegion.currencySymbol,
    homeUrl: currentRegion.homeUrl,
    logoUrl: currentRegion.logoUrl
  },
  variant: {
    sku: variant.sku,
    studentPrice: variant.studentPrice
  },
})

const onAddItemToCart = (
  { payload: { quantity, variantId } }, { asset, currentRegion }
) => {
  const variant = asset.variants[variantId]

  if (variant !== undefined) {
    return({
      event: 'onAddToCart',
      ...itemFromCartProps({ asset, currentRegion, quantity, variant }),
      add_to_cart_item: [{
        ...itemProps({ asset, currentRegion, variant }),
        quantity: quantity,
      }],
    })
  }
}

const onRemoveItemFromCart = (
  { payload: itemId }, { currentRegion, lineItems }
) => {
  const { asset, variant, quantity } = lineItems.items[itemId]

  return({
    event: 'onRemoveFromCart',
    ...itemFromCartProps({ asset, currentRegion, quantity, variant }),
    remove_from_cart_item: [{
      ...itemProps({ asset, currentRegion, variant }),
      quantity: quantity,
    }],
  })
}

const onCheckout = (
  _action, { cart: { subTotal }, currentRegion, lineItems: { items } }
) => ({
  cart: { subTotal },
  checkout_items: cartItemsProps({ currentRegion, items }),
  event: 'onCheckout',
  lineItems: Object.values(items),
  region: {
    currency: currentRegion.storeCurrency,
    currencySymbol: currentRegion.currencySymbol,
    homeUrl: currentRegion.homeUrl,
    logoUrl: currentRegion.logoUrl
  }
})

const onCart = (
  _action,
  {
    cart: { subTotal },
    currentRegion,
    lineItems: { items },
    relatedAssets: { assets, assetsOrder }
  }
) => {
  const listType = CART_RECOMMENDATIONS

  return ({
    cart: { subTotal },
    cart_items: cartItemsProps({ currentRegion, items }),
    event: 'onCart',
    lineItems: Object.values(items),
    region: {
      currency: currentRegion.storeCurrency,
      currencySymbol: currentRegion.currencySymbol,
      homeUrl: currentRegion.homeUrl,
      logoUrl: currentRegion.logoUrl
    },
    relatedAssets: relatedAssetsProps({ assets, assetsOrder, listType })
  })
}

const viewProduct = ({ asset, currentRegion, event, selectedVariant }) => ({
  asset: assetProps(asset),
  event: event,
  region: {
    currency: currentRegion.storeCurrency,
    currencySymbol: currentRegion.currencySymbol,
    homeUrl: currentRegion.homeUrl,
    logoUrl: currentRegion.logoUrl
  },
  variant: {
    sku: selectedVariant.sku,
    studentPrice: selectedVariant.studentPrice
  }
})

const onViewProduct = (_action, { asset, currentRegion }) => {
  const { selectedVariant } = asset

  return ({
    ...viewProduct({
      asset,
      currentRegion,
      event: 'onViewProduct',
      selectedVariant: selectedVariant
    }),
    product_item: [
      itemProps({ asset, currentRegion, variant: selectedVariant })
    ]
  })
}

const onUpdateSelectedVariant = (
  { payload },
  { asset, currentRegion }
) => viewProduct({
  asset,
  currentRegion,
  event: 'onUpdateSelectedVariant',
  selectedVariant: payload
})

const selectedVariantProps = ({ asset, position, variant }) => {
  const { canonicalIsbn, imprintName, kind, title } = asset
  const { sku, studentPrice } = variant
  const name = formattedName({ canonicalIsbn, title })
  const id = formattedIsbn(canonicalIsbn)

  return ({
    brand: imprintName,
    category: kind,
    id: id,
    name: name,
    position: position,
    price: studentPrice,
    variant: sku
  })
}

const productClick = (
  { payload: { asset, position, variant, list } }, { currentRegion }
) => {
  const { listType } = list

  return ({
    ecommerce: {
      click: {
        actionField: { list: listType },
        products: [selectedVariantProps({ asset, position, variant })],
      }
    },
    event: "productClick",
    product_select_item: [
      recommendedItemProps({ asset, currentRegion, list, position, variant })
    ]
  })
}

const addToCart = ({ payload }) => {
  const { asset, currentRegion, list, position, variant } = payload
  const { listType } = list

  return ({
    add_to_cart_item: [recommendedItemProps({ ...payload })],
    ecommerce: {
      add: {
        actionField: { list: listType },
        products: [selectedVariantProps({ asset, position, variant })]
      },
      currencyCode: currentRegion.storeCurrency
    },
    event: "addToCart"
  })
}

const onPdpRecommendations = (_action, { asset, currentRegion }) => {
  const { relatedAssets } = asset
  const { assets, assetsOrder } = relatedAssets
  const listType = PDP_RECOMMENDATIONS
  const list = {
    listId: PDP_RECOMMENDED_PRODUCTS_ID,
    listName: PDP_RECOMMENDED_PRODUCTS
  }

  return ({
    ecommerce: {
      currencyCode: currentRegion.storeCurrency,
      impressions: relatedAssetsProps({ assets, assetsOrder, listType })
    },
    event: 'onPdpRecommendations',
    pdp_recommended_items: recommendedAssetsProps(
      { assets, assetsOrder, currentRegion, list }
    )
  })
}

const onCartRecommendations = (_action, { relatedAssets, currentRegion }) => {
  const { assets, assetsOrder } = relatedAssets
  const list = {
    listId: CART_RECOMMENDED_PRODUCTS_ID,
    listName: CART_RECOMMENDED_PRODUCTS
  }

  return ({
    cart_recommended_items: recommendedAssetsProps(
      { assets, assetsOrder, currentRegion, list }
    ),
    event: ON_CART_RECOMMENDATIONS
  })
}

const productClickSRP = (
  {
    payload: {
      canonicalIsbn, imprintName, kind, list, minPrice, position, title
    }
  },
  { currentRegion }
) => {
  const { listId, listName } = list
  const name = formattedName({ canonicalIsbn, title })
  const id = formattedIsbn(canonicalIsbn)

  return ({
    event: "productClickSRP",
    product_select_item: [
      {
        currency: currentRegion.storeCurrency,
        index: position,
        item_brand: imprintName,
        item_category: kind,
        item_id: id,
        item_list_id: listId,
        item_list_name: listName,
        item_name: name,
        price: minPrice
      }
    ]
  })
}

const promoCodeClick = ({ payload }) => {
  const { promoCodeInput, promoCodeStatus } = payload

  return ({
    event: "promoCodeClick",
    promoCode: {
      promoCodeInput,
      promoCodeStatus
    }
  })
}

export const eventsMap = {
  'gtm/Event': event,
  'gtm/addToCart': addToCart,
  'gtm/consentUpdate': consentUpdate,
  'gtm/onCart': onCart,
  'gtm/onCartRecommendations': onCartRecommendations,
  'gtm/onCheckout': onCheckout,
  'gtm/onPdpRecommendations': onPdpRecommendations,
  'gtm/onViewProduct': onViewProduct,
  'gtm/productClick': productClick,
  'gtm/productClickSRP': productClickSRP,
  'gtm/promoCodeClick': promoCodeClick,
  [SHARED_ACTIONS.assets.updateSelectedVariant]: onUpdateSelectedVariant,
  [SHARED_ACTIONS.lineItems.createStart]: onAddItemToCart,
  [SHARED_ACTIONS.lineItems.destroyStart]: onRemoveItemFromCart
}
