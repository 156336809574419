import PropTypes from 'prop-types'

import Tooltip from '@/components/Tooltip'

import Icon from '../Tooltip/Icon'

const Label = ({ id, label, toolTipText }) => (
  <If condition={label}>
    <div className='clipboard-header' tabIndex='-1'>
      <label htmlFor={id}>{label}</label>
      <If condition={toolTipText}>
        <Tooltip tooltipText={toolTipText} ><Icon /></Tooltip>
      </If>
    </div>
  </If>
)

export default Label

Label.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  toolTipText: PropTypes.string
}
