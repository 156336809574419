export const SLICES = {
  accordion: 'accordion',
  activeRegions: 'activeRegions',
  activeSamplingRegions: 'activeSamplingRegions',
  adminPermissions: 'adminPermissions',
  adminRoleSwitcher: 'adminRoleSwitcher',
  asset: 'asset',
  billingAddress: 'billingAddress',
  bulkRedemptionGuidance: 'bulkRedemptionGuidance',
  cart: 'cart',
  checkout: 'checkout',
  currentLocale: 'currentLocale',
  currentRegion: 'currentRegion',
  dashboardMenu: 'dashboardMenu',
  expressCheckout: 'expressCheckout',
  featureFlags: 'featureFlags',
  flashMessages: 'flashMessages',
  ghostInspector: 'ghostInspector',
  guestUsers: 'guestUsers',
  institutions: 'institutions',
  lineItems: 'lineItems',
  miniCart: 'miniCart',
  mobileMenu: 'mobileMenu',
  modal: 'modal',
  onlineResourceModal: 'onlineResourceModal',
  orderHistory: 'orderHistory',
  promoCampaigns: 'promoCampaigns',
  publisherUsers: 'publisherUsers',
  recaptcha: 'recaptcha',
  regionRecommendation: 'regionRecommendation',
  relatedAssets: 'relatedAssets',
  taxes: 'taxes',
  user: 'user',
  whiteLabelStoreDemo: 'whiteLabelStoreDemo'
}

export const SHARED_ACTIONS = {
  assets: {
    updateSelectedVariant: `${SLICES.asset}/updateSelectedVariant`,
  },
  billingAddress: {
    updateStart: `${SLICES.billingAddress}/updateStart`,
    updateSuccess: `${SLICES.billingAddress}/updateSuccess`,
    updateSuccessV2: `${SLICES.billingAddress}/updateSuccessV2`,
  },
  cart: {
    applyPromoCodeSuccess:
      `${SLICES.cart}/applyPromoCodeSuccess`,
    removePromoCodeSuccess:
      `${SLICES.cart}/removePromoCodeSuccess`,
  },
  checkout: {
    applePayInstitutionRequired:
      `${SLICES.checkout}/applePayInstitutionRequired`,
    fetchCountrySubdivisionsSuccess:
      `${SLICES.checkout}/fetchCountrySubdivisionsSuccess`,
    getWatchmanUserBalanceFailure:
      `${SLICES.checkout}/getWatchmanUserBalanceFailure`,
    getWatchmanUserBalanceStart:
      `${SLICES.checkout}/getWatchmanUserBalanceStart`,
    payWithWatchmanBalanceFailure:
      `${SLICES.checkout}/payWithWatchmanBalanceFailure`,
    paypalAuthorizeFailure: `${SLICES.checkout}/paypalAuthorizeFailure`,
    paypalAuthorizeStart: `${SLICES.checkout}/paypalAuthorizeStart`,
    paypalAuthorizeSuccess: `${SLICES.checkout}/paypalAuthorizeSuccess`,
    paypalCreateFailure: `${SLICES.checkout}/paypalCreateFailure`,
    paypalCreateStart: `${SLICES.checkout}/paypalCreateStart`,
    paypalError: `${SLICES.checkout}/paypalError`,
    paypalExecuteFailure: `${SLICES.checkout}/paypalExecuteFailure`,
    paypalExecuteStart: `${SLICES.checkout}/paypalExecuteStart`,
    switchToBillingAddressStep: `${SLICES.checkout}/switchToBillingAddressStep`,
    updatePendingPurchaseInstitutionSuccess:
      `${SLICES.checkout}/updatePendingPurchaseInstitutionSuccess`,
  },
  lineItems: {
    createFailure: `${SLICES.lineItems}/createFailure`,
    createStart: `${SLICES.lineItems}/createStart`,
    createSuccess: `${SLICES.lineItems}/createSuccess`,
    destroyStart: `${SLICES.lineItems}/destroyStart`,
    destroySuccess: `${SLICES.lineItems}/destroySuccess`,
    updateStart: `${SLICES.lineItems}/updateStart`,
    updateSuccess: `${SLICES.lineItems}/updateSuccess`,
  },
  orderHistory: {
    pollBulkDownloadRequestStart:
      `${SLICES.orderHistory}/pollBulkDownloadRequestStart`,
  },
  publisherCampaigns: {
    updateFailure: `${SLICES.publisherCampaigns}/updateFailure`,
    updateStart: `${SLICES.publisherCampaigns}/updateStart`,
  },
  publisherUsers: {
    getRowsFailure: `${SLICES.publisherUsers}/getRowsFailure`,
    getRowsStart: `${SLICES.publisherUsers}/getRowsStart`,
    grantAccessFailure: `${SLICES.publisherUsers}/grantAccessFailure`,
    importUsersFailure: `${SLICES.publisherUsers}/importUsersFailure`,
    importUsersStart: `${SLICES.publisherUsers}/importUsersStart`,
  },
  updateStateFromBackend: 'all/updateStateFromBackend'
}
