import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'
import { mergeKeys } from '@/reducers/utils/helpers'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.bulkRedemptionGuidance]
})

const fetch = {
  fetchFailure: (state, { payload: error }) => mergeKeys(
    state,
    { status: { error, fetching: false } }
  ),
  fetchStart: (state, _action) => mergeKeys(
    state,
    { status: { error: null, fetching: true } }
  ),
  fetchSuccess: (state, { payload: request }) => ({
   ...request,
    status: {
      ...state.status,
      error: null,
      fetching: false
    }
  })
}

export const reducers = { ...fetch }

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
