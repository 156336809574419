import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const fetch = {
  fetchFailure: state => ({
    ...state,
    isPending: false,
    newRecord: {
      isEditing: false
    }
  }),
  fetchStart: state => ({
    ...state,
    isPending: true,
    newRecord: {
      isEditing: false
    }
  }),
  fetchSuccess: (state, { payload }) => ({
    ...payload,
    isPending: false,
    newRecord: {
      isEditing: false
    }
  })
}

const sendEmail = {
  sendEmailFailure: state => ({
    ...state,
    isPending: false
  }),
  sendEmailStart: state => ({
    ...state,
    isPending: true,
    newRecord: {
      isEditing: false
    }
  }),
  sendEmailSuccess: state => ({
    ...state,
    isPending: false
  })
}

const updateGuestUserEmail = {
  updateGuestUserEmailFailure: state => {
    const newRecord = { ...state.newRecord }
    
    return {
      ...state,
      isPending: false,
      newRecord: {
        isEditing: true,
        newValue: newRecord.newValue
      }
    }
  },
  updateGuestUserEmailStart: state => ({
    ...state,
    isPending: true
  }),
  updateGuestUserEmailSuccess: (state, { payload: { email } }) => ({
    ...state,
    isPending: false,
    newRecord: {
      isEditing: false,
      newValue: email
    }
  })
}

const clearNewRecord = state => {
  const newRecord = { ...state.newRecord }

  return {
    ...state,
    newRecord: {
      isEditing: false,
      newValue: newRecord.newValue
    }
  }
}

const showInlineEditForm = state => {
  const newRecord = { ...state.newRecord }

  return {
    ...state,
    newRecord: {
      isEditing: true,
      newValue: newRecord.newValue
    }
  }
}

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.guestUsers]
})

export const reducers = {
  ...fetch,
  ...sendEmail,
  ...updateGuestUserEmail,
  clearNewRecord,
  showInlineEditForm
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
