import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Clipboard from '@/components/Clipboard'
import { translate } from '@/utils/translations'

import MarkdownReader from '../../MarkdownReader'
import Footnotes from './Footnotes'
import RefundInfo from './RefundInfo'
import WebsiteButtonOutline from './WebsiteButtonOutline'

const Instructions = ({ isbn }) => {
  const asset = useSelector(state => state.onlineResourceModal)[isbn]
  const { loginUrl, accessCode, courseKey } = asset

  useEffect(() => {
    document.getElementsByClassName('clipboard-header')[0].focus()
  }, [])

  return (
    <>
      <div className='image-and-clipboard'>
        <div className='refund-info-section'>
          <RefundInfo />
          <div className='clipboards'>
            <Clipboard
              id={'access-code'}
              label={translate('common.access_code')}
              textToBeCopied={accessCode || courseKey}
            />
          </div>
        </div>
      </div>
      <Choose>
        <When condition={loginUrl}>
          <p className='mt-3 mb-0'>
            {translate('order_history.form.access_code.third_step')}
            <a
              aria-label={translate('common.footnote_asterisk')}
              href='#coursekey'
              id='footnotes-ref'
            >{'[*]'}</a>
          </p>
          <WebsiteButtonOutline isbn={isbn} />
        </When>
        <Otherwise>
          <div className='mt-3 mb-5 inline-footnote'>
            <MarkdownReader>
              {translate(
                'order_history.form.access_code.third_step_no_url'
              )}
            </MarkdownReader>
            <a
              aria-label={translate('common.footnote_asterisk')}
              href='#coursekey'
              id='footnotes-ref'
            >{'[*]'}</a>
          </div>
        </Otherwise>
      </Choose>
      <Footnotes />
    </>
  )
}

Instructions.propTypes = { isbn: PropTypes.string.isRequired }

export default Instructions
