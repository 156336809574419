import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.asset]
})

const updateSelectedVariant = (state, { payload }) => ({
  ...state,
  selectedVariant: payload
})

const hydrateVariants = (state, { payload }) => ({
  ...state,
  variants: payload
})

const updateQuantity = (state, { payload }) => ({
  ...state,
  quantity: payload
})

const fetchTableOfContents = {
  fetchTableOfContentsFailure: (state, { payload }) => ({
    ...state,
    tableOfContents: {
      error: payload.error,
      isLoading: false,
      response: []
    }
  }),
  fetchTableOfContentsStart: state => ({
    ...state,
    tableOfContents: {
      isLoading: true
    }
  }),
  fetchTableOfContentsSuccess: (state, { payload }) => ({
    ...state,
    tableOfContents: {
      error: null,
      isLoading: false,
      response: payload,
    }
  })
}

const fetchOnlineResource = {
  fetchOnlineResourceFailure: (state, { payload }) => ({
    ...state,
    onlineResource: {
      error: payload,
      isLoading: true,
      loginURL: null
    }
  }),
  fetchOnlineResourceStart: state => ({
    ...state,
    isLoading: true
  }),
  fetchOnlineResourceSuccess: (state, { payload }) => ({
    ...state,
    onlineResource: {
      error: null,
      isLoading: false,
      loginURL: payload.eresourceHref
    }
  })
}

const fetchRelatedAssets = {
  fetchRelatedAssetsFailure: (state, { payload }) => ({
    ...state,
    relatedAssets: {
      error: payload,
      isLoading: true,
    }
  }),
  fetchRelatedAssetsStart: state => ({
    ...state,
    isLoading: true
  }),
  fetchRelatedAssetsSuccess: (state, { payload }) => ({
    ...state,
    relatedAssets: {
      error: null,
      isLoading: false,
      ...payload
    }
  })
}

export const reducers = {
  ...fetchRelatedAssets,
  ...fetchOnlineResource,
  ...fetchTableOfContents,
  hydrateVariants,
  updateQuantity,
  updateSelectedVariant
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
