import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  companies: [],
  companiesFilterString: '',
  companyId: null,
  menu: 'role', // or company
  openMenu: null, // null, role, or company
  recentCompanies: [],
  role: null
}

const adminRoleSwitcher = createSlice({
  extraReducers,
  initialState,
  name: SLICES.adminRoleSwitcher,
  reducers: reducers
})

export const { actions } = adminRoleSwitcher
export default adminRoleSwitcher
