import PropTypes from 'prop-types'

import { ASSET_KINDS } from '@/utils/constants'
import { translate } from '@/utils/translations'

import { humanizeBulkCodeCount } from './helpers'

const cardStyles = [
  'asset-metadata-section',
  'd-flex',
  'flex-column',
  'flex-sm-row',
  'justify-content-between'
].join(' ')

const AssetMetadata = ({
  bulkTotal,
  imageUrl,
  isbn,
  kind,
  price,
  title,
  totalCodes
}) => (
  <div className={cardStyles}>
    <div className='d-flex flex-column flex-sm-row align-items-start'>
      <img alt='' className='cover-image' src={imageUrl} />
      <div className='mx-sm-4 mt-4 mt-sm-0'>
        <Choose>
          <When condition={kind === ASSET_KINDS.online_resource_bundle}>
            <span className='pill-outline-secondary'>
              {translate('order_history.kind.book')}
            </span>
            <span className='pill-outline-secondary'>
              {translate('order_history.kind.online_resource')}
            </span>
          </When>
          <Otherwise>
            <If condition={kind}>
              <span className='pill-outline-secondary'>
                {kind}
              </span>
            </If>
          </Otherwise>
        </Choose>
        <p className='mt-2 m-0 u-weight--bold'>{title}</p>
        <p className='m-0'>
          {`${translate('order_history.isbn')} ${isbn}`}
        </p>
        <If condition={totalCodes}>
          <p className='m-0'>
            {humanizeBulkCodeCount(totalCodes)}
          </p>
        </If>
      </div>
    </div>
    <If condition={price || bulkTotal}>
      <div className='col-sm-3 m-0 p-0'>
        <p className='m-0 u-weight--bold u-float--right u-align--right'>
          {bulkTotal || price}
        </p>
      </div>
    </If>
  </div>
)

AssetMetadata.propTypes = {
  bulkTotal: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  isbn: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  price: PropTypes.string,
  title: PropTypes.string.isRequired,
  totalCodes: PropTypes.number
}

export default AssetMetadata
