import { HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import PropTypes from 'prop-types'

import { DEPLOYED_ENVIRONMENTS } from '@/utils/constants'
import honeybadger from '@/utils/honeybadger'

const ThirdPartyErrorBoundary = ({ children }) => {
  const RAILS_ENV = process.env.RAILS_ENV

  const isDeployedEnvironment = DEPLOYED_ENVIRONMENTS.includes(RAILS_ENV)

  return (
    <Choose>
      <When condition={isDeployedEnvironment}>
        <HoneybadgerErrorBoundary honeybadger={honeybadger}>
          {children}
        </HoneybadgerErrorBoundary>
      </When>
      <Otherwise>
        {children}
      </Otherwise>
    </Choose>
  )
}

export default ThirdPartyErrorBoundary

ThirdPartyErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
}
