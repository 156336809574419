import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Spinner from '@/components/Spinner'
import {
  fetchExpressCheckoutAssetDetails
} from '@/reducers/expressCheckout/asyncActions'
import {
  expressCheckoutHasSavedPaymentSelector,
  expressCheckoutParamsSelector
} from '@/selectors'

import OrderSummary from './OrderSummary'
import GrandTotal from './OrderSummary/GrandTotal'
import Savings from './OrderSummary/Savings'
import Subtotal from './OrderSummary/Subtotal'
import TaxLabel from './OrderSummary/TaxLabel'
import PlaceOrderButton from './PlaceOrderButton'
import ProceedToCheckout from './ProceedToCheckout'
import SavedPaymentInformation from './SavedPaymentInformation'
import TitleDetails from './TitleDetails'

const Content = () => {
  const isLoading = useSelector(state => state.expressCheckout.isLoading)
  const expressCheckoutParams = useSelector(expressCheckoutParamsSelector)
  const hasSavedPayment = useSelector(expressCheckoutHasSavedPaymentSelector)
  const dispatch = useDispatch()
  const hasLoaded = useSelector(state => state.expressCheckout.hasLoaded)

  useEffect(() => {
    if (!hasLoaded) {
      dispatch(fetchExpressCheckoutAssetDetails(expressCheckoutParams))
    }
  }, [])

  return (
    <Choose>
      <When condition={isLoading}>
        <Spinner />
      </When>
      <Otherwise>
        <TitleDetails />
        <Choose>
          <When condition={hasSavedPayment}>
            <SavedPaymentInformation />
            <OrderSummary>
              <Subtotal />
              <TaxLabel />
              <Savings />
              <GrandTotal />
            </OrderSummary>
            <PlaceOrderButton />
          </When>
          <Otherwise>
            <OrderSummary>
              <TaxLabel />
              <Savings />
              <hr aria-hidden />
              <Subtotal isBold />
            </OrderSummary>
            <ProceedToCheckout />
          </Otherwise>
        </Choose>
      </Otherwise>
    </Choose>
  )
}

export default Content
