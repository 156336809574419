import { createSelector } from '@reduxjs/toolkit'
import every from 'lodash/every'

import { APPLE_PAY_VERSION } from '@/constants/applePay'
import {
  CREDIT_CARD_PAYMENT_TYPES,
  FORM_STEPS,
  PAYMENT_REQUEST_LABEL,
  PAYMENT_TYPES,
  STRIPE_STATES } from '@/constants/checkout'
import {
  hasItemsSelector,
  hasRestrictedItemsSelector,
  subTotalAsCentsSelector
} from '@/selectors'
import { translate } from '@/utils/translations'

const applePaySessionSupported = () => {
  try {
    return (
      typeof window.ApplePaySession !== 'undefined'
      && window.ApplePaySession.canMakePayments()
      && window.ApplePaySession.supportsVersion(APPLE_PAY_VERSION)
    )
  } catch(_error) {
    return false
  }
}

export const showApplePayOptionSelector = createSelector(
  [
    state => state.checkout.paymentGateways,
    state => state.checkout.isApplePayEnabled
  ],
  (paymentGateways, isApplePayEnabled) => (
    paymentGateways.includes(PAYMENT_TYPES.applepay)
    && isApplePayEnabled
    && applePaySessionSupported()
  )
)

export const showFinancialAidOptionSelector = createSelector(
  [
    state => state.checkout.paymentGateways,
    state => state.checkout.isFinancialAidEnabled
  ],
  (paymentGateways, isFinancialAidEnabled) => (
    paymentGateways.includes(PAYMENT_TYPES.watchman)
    && isFinancialAidEnabled
  )
)

export const canPurchaseWithFinancialAidSelector = createSelector(
  [state => state.checkout.watchmanUserBalanceLeft],
  balance => balance > 0
)

export const onlyCreditCartOptionSelector = createSelector(
  [
    state => state.checkout.paymentGateways,
    state => state.checkout.showPaypalOption,
    showApplePayOptionSelector
  ],
  (paymentGateways, showPaypalOption, showApplePayOption) => {
    return every(
      paymentGateways,
      paymentGateway => CREDIT_CARD_PAYMENT_TYPES.includes(paymentGateway)
    ) || (!showPaypalOption && !showApplePayOption)
  }
)

export const noPaymentTypesAvailableSelector = createSelector(
  [
    onlyCreditCartOptionSelector,
    state => state.checkout.isCreditCardDisabled,
    state => state.checkout.paymentGateways
  ],
  (onlyCreditCartOption, isCreditCardDisabled, paymentGateways) => (
    (onlyCreditCartOption && isCreditCardDisabled)
    || paymentGateways.length === 0
  )
)

export const showPaymentChoiceStepSelector = createSelector(
  [
    onlyCreditCartOptionSelector,
    noPaymentTypesAvailableSelector
  ],
  (onlyCreditCartOption, noPaymentTypesAvailable) => (
    !onlyCreditCartOption || noPaymentTypesAvailable
  )
)

export const assetsPurchaseableInCountrySelector = createSelector(
  [
    hasItemsSelector,
    state => state.billingAddress.country,
    state => state.currentRegion.parentName,
    hasRestrictedItemsSelector
  ],
  (hasItems, country, parentName, hasRestrictedItems) => (
    country === parentName || !hasRestrictedItems || hasItems
  )
)

export const showPaypalConfirmPurchaseButtonSelector = createSelector(
  [state => state.checkout.activeStep],
  activeStep => (
    activeStep === FORM_STEPS.paypalConfirmPurchase
  )
)

export const isCountryNativeToRegionSelector = createSelector(
  [
    state => state.currentRegion.countryCodes,
    state => state.billingAddress.country
  ],
  (currentRegionCountries, billingAddressCountry) => (
    (currentRegionCountries || []).includes(billingAddressCountry)
  )
)

export const isInstitutionRequiredSelector = createSelector(
  [
    state => state.checkout.isGuestReferenceUser,
    state => state.checkout.isInternationalPurchasingEnabled,
    isCountryNativeToRegionSelector
  ],
  (
    isGuestReferenceUser,
    isInternationalPurchasingEnabled,
    isCountryNativeToRegion
  ) => {
    const isInternationalPurchase = isInternationalPurchasingEnabled
      && !isCountryNativeToRegion

    return isGuestReferenceUser || isInternationalPurchase
  }
)

export const hasCountrySubdivisionsSelector = createSelector(
  [state => state.checkout.countrySubdivisions],
  countrySubdivisions => countrySubdivisions.length > 0
)

export const billingAddressMessageSelector = createSelector(
  [
    state => state.checkout.isInternationalPurchasingEnabled,
    state => state.checkout.translations.billingAddressMessage
  ],
  (isInternationalPurchasingEnabled, billingAddressMessage) => (
    isInternationalPurchasingEnabled ? '' : billingAddressMessage
  )
)

export const institutionPlaceholderTextSelector = createSelector(
  [
    state => state.institutions.institution.name
  ],
  name => name || translate('checkout.institution_step.placeholder')
)

export const isInstitutionContinueWaitingSelector = createSelector(
  [
    state => state.billingAddress.isUpdating,
    state => state.checkout.showSpinner
  ],
  (isBillingAddressUpdating, isShowSpinner) => (
    isBillingAddressUpdating || isShowSpinner
  )
)

export const isPayingWithCreditCardSelector = createSelector(
  [
    state => state.checkout.chosenPaymentType
  ],
  chosenPaymentType => (
    CREDIT_CARD_PAYMENT_TYPES.includes(chosenPaymentType)
  )
)

export const paymentsApiRouteStringSelector = createSelector(
  [
    state => state.checkout.creditCardPaymentGateway
  ],
  creditCardPaymentGateway => {
    const { stripe } = PAYMENT_TYPES

    switch(creditCardPaymentGateway) {
      case stripe:
        return 'apiPaymentsStripe'
    }
  }
)

export const stripeErrorMessagesSelector = createSelector(
  [
    state => state.checkout.stripeErrors
  ],
  stripeErrors => {
    if(stripeErrors.message && stripeErrors.message === 'Network Error') {
      return [translate('errors.network_purchase_error')]
    } else if(stripeErrors.length > 0) {
      return stripeErrors.map(error => error.message)
    } else {
      return []
    }
  }
)

const stripeErrorMessagesV2Selector = createSelector(
  [
    state => state.checkout.stripeErrors
  ],
  stripeErrors => {
    if(stripeErrors.message && stripeErrors.message === 'Network Error') {
      return [translate('errors.network_purchase_error')]
    } else if(stripeErrors.length > 0) {
      return stripeErrors.map(error => {
        switch (error.code) {
          case 'incomplete_first_name':
          case 'incomplete_last_name':
          case 'incomplete_address':
          case 'incomplete_number':
          case 'incomplete_cvc':
          case 'incomplete_expiry':
            return null
          default:
            return error.message
        }
      }).filter(Boolean)
    } else {
      return []
    }
  }
)

export const hasSavedPaymentSelector = createSelector(
  [state => state.checkout.hasSavedPayment],
  hasSavedPayment => hasSavedPayment
)

export const hasStripeErrorMessageSelector = createSelector(
  [stripeErrorMessagesSelector],
  errors => errors.length > 0
)

const billingAddressErrorsSelector = createSelector(
  [state => state.billingAddress.errors],
  errors => Object.values(errors)
)

export const stripeV2ErrorsSelector = createSelector(
  [
    stripeErrorMessagesV2Selector,
    billingAddressErrorsSelector
  ],
  (stripeErrors, billingAddressErrors) => (
    stripeErrors.concat(billingAddressErrors)
  )
)

export const showSavedPaymentDetailsSelector = createSelector(
  [
    isPayingWithCreditCardSelector,
    hasSavedPaymentSelector,
  ],
  (isPayingWithCreditCard, hasSavedPayment) => (
    isPayingWithCreditCard && hasSavedPayment
  )
)

export const isShowingSavedPaymentDetailSelector = createSelector(
  [
    showSavedPaymentDetailsSelector,
    state => state.checkout.isPaymentInfoEditable,
    state => state.checkout.isEditingBillingAddress,
  ],
  (showSavedPaymentDetails, isPaymentInfoEditable, isEditingBillingAddress) => (
    showSavedPaymentDetails
    && !isPaymentInfoEditable
    && !isEditingBillingAddress
  )
)

export const isSavingCustomerPaymentMethod = createSelector(
  [state => state.checkout.isSavingCustomerPaymentMethod],
  isSavingCustomerPaymentMethod => isSavingCustomerPaymentMethod
)

export const isRenderingRecaptchaSelector = createSelector(
  [
    state => state.checkout.isForcingRecaptcha,
    state => state.checkout.hasSolvedRecaptcha
  ],
  (isForcingRecaptcha, hasSolvedRecaptcha) => (
    isForcingRecaptcha && !hasSolvedRecaptcha
  )
)

export const isRenderingRecaptchaInPaymentInfoStepSelector = createSelector(
  [
    isRenderingRecaptchaSelector,
    state => state.checkout.hasSubmittedCardForm,
    state => state.featureFlags.stripeV2Enabled
  ],
  (isRenderingRecaptcha, hasSubmittedCardForm, stripeV2Enabled) => {
    if(stripeV2Enabled) {
      return isRenderingRecaptcha && hasSubmittedCardForm
    } else {
      return isRenderingRecaptcha && !hasSubmittedCardForm
    }
  }
)

export const isShowingExpressCheckoutSelector = createSelector(
  [
    state => state.checkout.expressCheckoutEnabled,
    state => state.currentRegion.bulkStoreKey
  ],
  (isExpressCheckoutEnabled, bulkStoreKey) => (
    isExpressCheckoutEnabled && bulkStoreKey !== null
  )
)

export const savedBillingDetailsSelector = createSelector(
  [state => state.checkout.savedBillingDetails],
  savedBillingDetails => savedBillingDetails
)

export const stripePaymentRequestSelector = createSelector(
  [
    state => state.currentRegion.currencyLabel,
    state => state.currentRegion.countryCodes,
    subTotalAsCentsSelector,
  ],
  (currencyLabel, countryCodes, subTotalInCents) => ({
    country: countryCodes[0],
    currency: currencyLabel.toLowerCase(),
    requestPayerName: true,
    total: {
      amount: Math.round(subTotalInCents),
      label: PAYMENT_REQUEST_LABEL
    }
  })
)

export const currencyMultiplierSelector = createSelector(
  [state => state.checkout.currencyMultiplier],
  currencyMultiplier => currencyMultiplier
)

export const subTotalAsRegionCentsSelector = createSelector(
  [
    currencyMultiplierSelector,
    state => state.cart.subTotal
  ],
  (currencyMultiplier, subTotal) => (Math.round(subTotal * currencyMultiplier))
)

export const creditCardInformationSelector = createSelector(
  [
    state => state.checkout.savedCardDetails.cardBrand,
    state => state.checkout.savedCardDetails.cardLastFour
  ],
  (cardBrand, cardLastFour) => {
    const cardLabel = translate('checkout.saved_payment.credit_card')
    const ucCardBrand = cardBrand.charAt(0).toUpperCase() + cardBrand.slice(1)

    return `${cardLabel}: ${ucCardBrand} …${cardLastFour}`
  }
)

export const creditCardExpirationSelector = createSelector(
  [
    state => state.checkout.savedCardDetails.cardExpMonth,
    state => state.checkout.savedCardDetails.cardExpYear
  ],
  (cardExpMonth, cardExpYear) => {
    const expirationLabel = translate('checkout.saved_payment.expiration_date')

    return `${expirationLabel}: ${cardExpMonth} / ${cardExpYear}`
  }
)

export const showPaymentChangeLinkSelector = createSelector(
  [
    showSavedPaymentDetailsSelector,
    state => state.checkout.activeStep
  ],
  (showPaymentDetails, activeStep) => (
    showPaymentDetails && activeStep !== FORM_STEPS.billingAddress
  )
)

export const isShowingSavedPaymentPreviewSelector = createSelector(
  [state => state.checkout.activeStep],
  activeStep => activeStep === FORM_STEPS.savedPaymentPreview
)

export const isCreditCardStepSelector = createSelector(
  [state => state.checkout.activeStep],
  activeStep => activeStep === FORM_STEPS.creditCardPayment
)

export const isStripeInitialStateSelector = createSelector(
  [
    state => state.checkout.stripeState,
  ],
  stripeState => stripeState === STRIPE_STATES.initial
)

export const showSavedPaymentPlaceOrderButtonSelector = createSelector(
  [
    isCreditCardStepSelector,
    isShowingSavedPaymentPreviewSelector
  ],
  (isCreditCardStep, isShowingSavedPaymentPreview) => (
    isCreditCardStep || isShowingSavedPaymentPreview
  )
)
export const isSubmitOrderButtonWaitingSelector = createSelector(
  [
    state => state.billingAddress.isUpdating,
    isStripeInitialStateSelector,
  ],
  (isUpdating, isStripeInitialState) => (
    isUpdating || !isStripeInitialState
  )
)

export const isPaymentTypePayPalSelector = createSelector(
  [state => state.checkout.chosenPaymentType],
  type => type === 'paypal'
)

export const hasExpressCheckoutPaymentGatewaySelector = createSelector(
  [state => state.checkout.paymentGateways],
  gateways => {
    const { applepay, paypal, watchman } = PAYMENT_TYPES

    return (
      gateways.includes(paypal)
      || gateways.includes(applepay)
      || gateways.includes(watchman)
    )
  }
)

export const isShowingExpressCheckoutButtons = createSelector(
  [
    hasExpressCheckoutPaymentGatewaySelector,
    isShowingSavedPaymentDetailSelector,
    isPaymentTypePayPalSelector,
    state => state.currentRegion.bulkEnabled
  ],
  (
    hasGateway,
    isShowingSavedPayment,
    isPayPal,
    isBulkEnabled,
  ) => (
    hasGateway
    && !isShowingSavedPayment
    && !isPayPal
    && !isBulkEnabled
  )
)

export const allowedCountriesForPurchaseSelector = createSelector(
  [
    state => state.checkout.countrySelectOptions
  ],
  countries => countries.map(country => country.value)
)

export const isShowingPayAnotherWayContentSelector = createSelector(
  [state => state.checkout.chosenPaymentType],
  paymentType => paymentType !== PAYMENT_TYPES.applepay
)
