import { PERPETUAL } from '@/constants/durations'
import LicenseExpiration from
'@/utils/factory/licenseInformation/licenseExpiration'

class CartLicenseExpiration extends LicenseExpiration {
  constructor({
    duration,
    durationInDays,
    daysLeftInLicense,
    isOnlineResource,
    isSubscription
  }) {
    super({
      daysLeftInLicense,
      duration,
      durationInDays,
      isOnlineResource
    })

    const isShowingExpiration = () => {
      if (isOnlineResource) {
        return duration !== PERPETUAL
      } else {
        return !isSubscription
      }
    }

    this.isShowing = isShowingExpiration()
  }
}

export default CartLicenseExpiration
