import { durationText } from '@/utils/durationUtils'

class LicenseDuration {
  constructor({ duration, isOnlineResource, isSubscription })
  {
    this.isPerpetual = duration === 'perpetual' && !isOnlineResource
    this.isShowing = !isSubscription
    this.text = durationText(duration)
  }
}

export default LicenseDuration
