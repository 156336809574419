import { useSelector } from 'react-redux'

import { translate } from '@/utils/translations'

const Title = () => {
  const currencyLabel = useSelector(state => state.currentRegion.currencyLabel)

  return (
    <li>
      <p>
        <strong>
          {translate('carts.order_summary')}
        </strong>
      </p>
      <p>
        <strong>
          {currencyLabel}
        </strong>
      </p>
    </li>
  )
}

export default Title
