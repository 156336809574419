import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import PopperContent from './PopperContent'
const ESCAPE_KEY_CODE = 27
const DELAY_IN_MILLISECONDS = 500

require('./index.scss')

const Tooltip = ({
  ariaLabelledBy,
  ariaText,
  buttonId,
  children,
  tooltipText
}) => {
  const TOOLTIP_CONTENT_ID = `tooltip-content-${Math.random()}`

  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)

  let closeTimeout, openTimeout

  const handleBlur = () => close()
  const handleFocus = () => delayedOpen()

  const handleKeyDown = event => {
    if (open && event.keyCode === ESCAPE_KEY_CODE) close()
  }

  const handleTargetMouseEnter = event => {
    if (!event.target.disabled) delayedOpen()
  }

  const handleTargetMouseLeave = () => delayedClose()

  const handlePopperMouseEnter = () => clearCloseTimeout()

  const handlePopperMouseLeave = () => delayedClose()

  const clearOpenTimeout = () => clearTimeout(openTimeout)

  const clearCloseTimeout = () => clearTimeout(closeTimeout)

  const delayedOpen = () => {
    clearOpenTimeout()
    clearCloseTimeout()
    if (!isOpen) {
      openTimeout = setTimeout(() => {
        setIsOpen(true)
      }, DELAY_IN_MILLISECONDS)
    }
  }

  const close = () => {
    clearOpenTimeout()
    clearCloseTimeout()
    setIsOpen(false)
  }

  const delayedClose = () => {
    clearOpenTimeout()
    clearCloseTimeout()

    closeTimeout = setTimeout(() => {
      setIsOpen(false)
    }, DELAY_IN_MILLISECONDS)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      clearOpenTimeout()
      clearCloseTimeout()
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <>
      <button
        aria-describedby={TOOLTIP_CONTENT_ID}
        aria-label={ariaText}
        aria-labelledby={ariaLabelledBy}
        className='new_tooltip trigger d-inline-block'
        id={buttonId}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onMouseEnter={handleTargetMouseEnter}
        onMouseLeave={handleTargetMouseLeave}
        ref={setReferenceElement}
      >
        {children}
      </button>

      <div className='sr-only' id={TOOLTIP_CONTENT_ID}>
        {tooltipText}
      </div>

      <PopperContent
        isOpen={isOpen}
        onEnter={handlePopperMouseEnter}
        onLeave={handlePopperMouseLeave}
        referenceElement={referenceElement}
      >
        {tooltipText}
      </PopperContent>
    </>
  )
}

export default Tooltip

Tooltip.propTypes = {
  ariaLabelledBy: PropTypes.string,
  ariaText: PropTypes.string,
  buttonId: PropTypes.string,
  children: PropTypes.object.isRequired,
  tooltipText: PropTypes.string.isRequired
}
