import PropTypes from 'prop-types'
import { useEffect } from 'react'

import store from '@/store'
import { eventDispatcher } from '@/utils/eventsHelper'

const VitalSourceEvents = ({ events, state }) => {
  useEffect(() => { eventDispatcher({ events, state, store }) }, [])

  return null
}

VitalSourceEvents.propTypes = {
  events: PropTypes.array,
  state: PropTypes.object
}

export default VitalSourceEvents
