export const DIALOG_TYPES = {
  error: 'dialog_as_error',
  success: 'dialog_as_success'
}

export const TOAST_TYPES = {
  error: 'toast_as_error',
  notice: 'toast_as_notice',
  success: 'toast_as_success'
}

export const PAGE_LEVEL_MESSAGE_TYPES = {
  error: 'page_level_message_as_error',
  success: 'page_level_message_as_success'
}
