import Spinner from '@/components/Spinner'

const className = [
  'vst-btn--large-full-width',
  'vst-btn--orange',
  'add_to_cart'
].join(' ')

const Loader = () => <div className={className}><Spinner /></div>

export default Loader
