import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  invisibleKey: null,
  response: null,
  visibleKey: null,
}

const recaptcha = createSlice({
  extraReducers,
  initialState,
  name: SLICES.recaptcha,
  reducers
})

export const { actions } = recaptcha
export default recaptcha
