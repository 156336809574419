import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  abbreviation: 'US',
  bulkEnabled: false,
  currencyLabel: null,
  pathPrefix: null,
  samplingEnabled: false,
  taxMultiplier: 1
}

const currentRegion = createSlice({
  extraReducers,
  initialState,
  name: SLICES.currentRegion,
  reducers
})

export const { actions } = currentRegion
export default currentRegion
