import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { translate } from '@/utils/translations'

import Instructions from './Instructions'
import WebsiteButtonOcean from './WebsiteButtonOcean'

const AccessCode = ({ isbn }) => {
  const skipReveal = useSelector(
    state => state.onlineResourceModal
  )[isbn].skipReveal

  return (
    <div className='u-align--left'>
      <Choose>
        <When condition={skipReveal}>
          <div className='mt-4'>
            {translate(
              'order_history.form.access_code.visit_publisher_site'
            )}
            <WebsiteButtonOcean isbn={isbn} />
          </div>
        </When>
        <Otherwise>
          <Instructions isbn={isbn} />
        </Otherwise>
      </Choose>
    </div>
  )
}

AccessCode.propTypes = { isbn: PropTypes.string.isRequired }

export default AccessCode
