import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { expirationSelector } from '@/selectors'

import ReduxProvider from '../ReduxProvider'
import SkeletonThemeWrapper from '../SkeletonThemeWrapper'

const Expiration = ({ isShowingExpiration }) => {
  const expiration = useSelector(expirationSelector)
  const [isShowingComponent, setIsShowingComponent] = useState(false)

  useEffect(() => {
    setIsShowingComponent(true)
  })

  return (
    <If condition={isShowingExpiration}>
      <Choose>
        <When condition={isShowingComponent}>
          <div className='expiration-text-display u-soft-half--sides'>
            {expiration}
          </div>
        </When>
        <Otherwise>
          <SkeletonThemeWrapper count={1} />
        </Otherwise>
      </Choose>
    </If>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = ({ isShowingExpiration }) => (
  <ReduxProvider>
    <Expiration isShowingExpiration={isShowingExpiration} />
  </ReduxProvider>
)

export default Component

Component.propTypes = { isShowingExpiration: PropTypes.bool.isRequired }
Expiration.propTypes = { isShowingExpiration: PropTypes.bool.isRequired }
