import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import Tooltip from '@/components/Tooltip'

import SkeletonThemeWrapper from '../SkeletonThemeWrapper'
import Icon from '../Tooltip/Icon'

const Title = ({
  isPackage,
  title,
  translations: { purchase_rent_or_buy_package, tooltips_package }
}) => {

  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    setIsShowing(true)
  })

  return (
    <Choose>
      <When condition={isPackage}>
        <div className='row'>
          <legend className='title d-flex justify-content-center'>
            {purchase_rent_or_buy_package}
            <Choose>
              <When condition={isShowing}>
                <Tooltip tooltipText={tooltips_package} >
                  <Icon />
                </Tooltip>
              </When>
              <Otherwise>
                <SkeletonThemeWrapper count={1} />
              </Otherwise>
            </Choose>
          </legend>
        </div>
      </When>
      <Otherwise>
        <legend className='title'>
          {title}
        </legend>
      </Otherwise>
    </Choose>
  )
}

export default Title

Title.propTypes = {
  isPackage: PropTypes.bool.isRequired,
  title: PropTypes.string,
  translations: PropTypes.shape({
    purchase_rent_or_buy_package: PropTypes.string.isRequired,
    tooltips_package: PropTypes.string.isRequired
  })
}
