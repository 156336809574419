import { useSelector } from 'react-redux'

import { pageLevelMessageSelector } from '@/selectors'

import Message from './components/Message'
const PageLevelMessage = () => {
  const message = useSelector(pageLevelMessageSelector)

  return (
    <If condition={message}>
      <Message message={message.text} type={message.type} />
    </If>
  )
}

export default PageLevelMessage
