import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const mergeState = (state, { payload }) => ({
  ...state, ...payload[SLICES.ghostInspector]
})

const setIsUpdating = (state, { payload: isUpdating }) => ({
  ...state,
  errors: null,
  isUpdating
})

const handleErrors = (state, { payload }) => ({
  ...state,
  errors: payload,
  isUpdating: false
})

export const reducers = {
  handleErrors,
  mergeState,
  setIsUpdating,
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: mergeState
}
