import omit from 'lodash/omit'

import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

export const remove = (state, { payload: id }) => omit(state, [id])

export const waiting = (state, { payload: id }) => ({
  ...state,
  [id]: { ...state[id], isWaiting: true }
})

export const update = (state, { payload: permission }) => ({
  ...state,
  [permission.id]: permission
})

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.adminPermissions]
})

export const reducers = {
  remove,
  update,
  waiting
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
