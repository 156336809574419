import PropTypes from 'prop-types'

const Spinner = ({
  styles,
  numberOfDisplayColumns,
  isInsideTable
}) => {
   styles = styles || 'sk-spinner sk-spinner-three-bounce'

  if (isInsideTable) {
    return (
      <tr>
        <td
          className='text-center'
          colSpan={numberOfDisplayColumns}
        >
          <div className={styles}>
            <div className='sk-bounce1' />
            <div className='sk-bounce2' />
            <div className='sk-bounce3' />
          </div>
        </td>
      </tr>
    )
  } else {
    return (
      <div className={styles} data-testid='spinner'>
        <div className='sk-bounce1' />
        <div className='sk-bounce2' />
        <div className='sk-bounce3' />
      </div>
    )
  }
}

Spinner.propTypes = {
  isInsideTable: PropTypes.bool,
  numberOfDisplayColumns: PropTypes.number,
  styles: PropTypes.string
}

export default Spinner
