import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ReduxProvider from '@/components/ReduxProvider'
import { routeFor } from '@/utils/router/'
import { translate } from '@/utils/translations'

const ReadNowButton = ({
  assetId,
  buttonText,
  className
}) => {
  const id = useSelector(state => state.asset.id) || assetId
  const eReaderURL = useSelector(state => state.currentRegion.eReaderUrl)
  const url = id ? routeFor('bookshelfRead', id) : eReaderURL

  return (
    <a
      className={className}
      href={url}
      rel="noopener noreferrer"
      target='_blank'
    >
      {buttonText || translate('common.read_in_bookshelf')}
    </a>
  )
}

/*eslint-disable react/no-multi-comp*/

const Component = ({
  assetId,
  buttonText,
  className
}) => (
  <ReduxProvider>
    <ReadNowButton {...{
      assetId,
      buttonText,
      className
    }}
    />
  </ReduxProvider>

)

export default Component

ReadNowButton.propTypes = {
  assetId: PropTypes.number,
  buttonText: PropTypes.string,
  className: PropTypes.string
}

Component.propTypes = {
  assetId: PropTypes.number,
  buttonText: PropTypes.string,
  className: PropTypes.string
}
