import expressCheckout from '@/reducers/expressCheckout'
import http from '@/utils/http'
import { routeFor } from '@/utils/router'
const { actions } = expressCheckout
import camelize from 'camelize'

export const fetchExpressCheckoutAssetDetails = ({
  assetId, duration, role
}) => dispatch => {
  dispatch(actions.fetchExpressCheckoutStart())

  return http.get(
    routeFor('apiExpressCheckoutAsset', assetId),
    { duration, role }
  )
  .then(response => {
    const camelizedResponse = camelize(response)

    dispatch(actions.fetchExpressCheckoutSuccess(camelizedResponse))
  }).catch(error => {
    dispatch(actions.fetchExpressCheckoutFailure(error))
  })
}
