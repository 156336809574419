import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.orderHistory]
})

const updateOrderHistory = {
  updateOrderHistoryFailure: state => ({
    ...state,
    isLoading: false,
    requestFailure: true,
    transactions: {}
  }),
  updateOrderHistorySuccess: (state, { payload }) => ({
    ...state,
    isLoading: false,
    requestFailure: false,
    transactions: { ...payload },
  })
}

const clearRefundSession = state => ({
  ...state,
  refund: {
    error: null,
    success: false
  }
})

const pollBulkDownloadRequest = {
  pollBulkDownloadRequestFailure: (state, { payload: id }) => ({
    ...state,
    bulkRequestStatus: {
      ...state['bulkRequestStatus'],
      [id]: { isPending: false }
    }
  }),
  pollBulkDownloadRequestStart: (state, { payload: id }) => ({
    ...state,
    bulkRequestStatus: {
      ...state['bulkRequestStatus'],
      [id]: { isPending: true }
    }
  }),
  pollBulkDownloadRequestSuccess: (state, { payload: id }) => ({
    ...state,
    bulkRequestStatus: {
      ...state['bulkRequestStatus'],
      [id]: { isPending: false }
    }
  })
}

const initiateRefund = {
  initiateRefundFailure: (state, { payload }) => ({
    ...state,
    refund: {
      error: payload.error.data.message,
      isSubmitting: false,
      success: false
    }
  }),
  initiateRefundStart: state => ({
    ...state,
    refund: {
      error: null,
      isSubmitting: true,
      success: false
    }
  }),
  initiateRefundSuccess: state => ({
    ...state,
    refund: {
      error: null,
      isSubmitting: false,
      success: true
    }
  })
}

export const reducers = {
  ...initiateRefund,
  ...pollBulkDownloadRequest,
  ...updateOrderHistory,
  clearRefundSession
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad
}
