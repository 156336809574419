import PropTypes from 'prop-types'

import { cartClassName } from './helpers'

const AddToCart = ({ action, children }) => (
  <button
    className={cartClassName}
    onClick={action}
  >
    {children}
  </button>
)

export default AddToCart

AddToCart.propTypes = {
  action: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
}
