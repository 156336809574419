import translations from './railsTranslations'

/**
 * @deprecated Do not use moving forward.
 * 
 * Please convert components to be stateful and provide translations from the
 * Rails side. See Account Center Component and tests for examples.
 */

export const translate = (translationKey, parameters) => {
  const locale = window.locale
  let translatedText = translations[locale][translationKey]

  for (let key in parameters) {
    const regex = new RegExp('%{' + key + '}', 'g')

    translatedText = translatedText.replace(regex, parameters[key])
  }

  if(!translatedText) {
    console.warn(`missing translation for ${translationKey} in ${locale}`)
  }

  return translatedText
}
