import 'url-search-params-polyfill'

export const appendRackMiniProfilerParam = data => ({
  ...data,
  ...(shouldTriggerRackMiniProfiler() ? { [profilerParamName]: true } : {})
})

const profilerParamName = 'rmp'

const shouldTriggerRackMiniProfiler = () => {
  const searchParams = new URLSearchParams(window.location.search)

  return searchParams.has(profilerParamName)
}
