import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialStatuses = {
  creating: false,
  destroying: false,
  error: null,
  fetching: false,
  updating: false
}

export const initialState = {
  items: {},
  status: initialStatuses
}

const lineItems = createSlice({
  extraReducers,
  initialState,
  name: SLICES.lineItems,
  reducers
})

export const { actions } = lineItems
export default lineItems
