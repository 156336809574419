import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import ButtonWithSpinner from '@/components/ButtonTo/ButtonWithSpinner'
import {
  fetch as fetchAccessCodes
} from '@/reducers/onlineResourceModal/asyncActions'
import { translate } from '@/utils/translations'

import RefundInfo from './RefundInfo'

const buttonStyles = [
  'vst-btn--medium',
  'vst-btn--ocean',
  'w-100'
].join(' ')

const ContinueButton = ({ isbn, isLoaded, setIsLoaded }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()

  const handleOnClick = () => {
    setIsSubmitting(true)
    dispatch(fetchAccessCodes({ vbid: isbn })).then(
      () => { setIsLoaded(true) }
    )
  }

  useEffect(() => {
    if (isLoaded) { setIsSubmitting(false) }
  }, [isLoaded])

  return (
    <div className='refund-info-section'>
      <RefundInfo />
      <Choose>
        <When condition={isSubmitting}>
          <ButtonWithSpinner className={buttonStyles} />
        </When>
        <Otherwise>
          <button
            className={buttonStyles}
            onClick={() => handleOnClick()}
          >
            {translate('order_history.button.continue')}
          </button>
        </Otherwise>
      </Choose>
    </div>
  )
}

ContinueButton.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  isbn: PropTypes.string.isRequired,
  setIsLoaded: PropTypes.func.isRequired
}

export default ContinueButton
