import { handleError } from '@/actions/general'
import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'
import http from '@/utils/http'
import { routeFor } from '@/utils/router'

const ROLE = 'role'
const COMPANY = 'company'
const COMPANY_PICKER_ROLES = ['publisher_admin', 'publisher_rep']

const onSwitchToRolePicker = (state, _action) => ({
  ...state,
  companyId: null,
  menu: ROLE,
  openMenu: ROLE
})

const onCompanyFilterChange = (state, { payload: companiesFilterString }) => ({
  ...state,
  companiesFilterString
})

const onClearRecentCompanies = (state, _action) => ({
  ...state,
  recentCompanies: []
})

const onSelectCompany = (state, { payload }) => {
  const { companyId, recentCompanies, role } = payload.props

  updateServerUserRole({ options: payload.props, state: payload.state })

  return {
    ...state,
    companyId: Number(companyId),
    recentCompanies,
    role
  }
}

const onSelectRole = (state, { payload }) => {
  const { recentCompanies, role } = payload.props

  if (COMPANY_PICKER_ROLES.includes(role)) {
    return { ...state, menu: COMPANY, openMenu: COMPANY, role }
  } else {
    updateServerUserRole({
      options: { companyId: null, recentCompanies, role },
      state: payload.state
    })

    return { ...state, companyId: null, recentCompanies, role }
  }
}

const onToggleMenu = (state, { payload }) => {
  const { menu, metadata, openMenu } = payload
  // metadata.source is undefined when the menu is open and the user clicks
  // off of the menu
  if (!metadata.source) { return { ...state, openMenu: null } }
  if (metadata.source !== 'click') { return state }

  return { ...state, openMenu: openMenu ? null : menu }
}

export const updateServerUserRole = ({ options, state }) =>
  http.put(routeFor('apiAdminRoleSwitcher', null, null, state), options)
    .then(data => window.location.assign(data.path))
    .catch(error => handleError(error))

export const reducers = {
  onClearRecentCompanies,
  onCompanyFilterChange,
  onSelectCompany,
  onSelectRole,
  onSwitchToRolePicker,
  onToggleMenu,
}

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.adminRoleSwitcher]
})

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
