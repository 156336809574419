import LocalePrice from './localePrice'
import StudentDiscountPrice from './studentDiscountPrice'
import StudentPrice from './studentPrice'

const Price = {
  LocalePrice,
  StudentDiscountPrice,
  StudentPrice,
}

export default {
  createPrice(type, attributes) {
    const PriceType = Price[type]

    return new PriceType(attributes)
  }
}
