import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  asset: {
    coverImageUrl: '',
    duration: '',
    expiration: '',
    subtitle: '',
    title: ''
  },
  error: false,
  hasLoaded: false,
  isLoading: true,
  isShowing: false,
  savedPayment: {
    brand: 'Visa',
    expiration: '03/2026',
    lastFour: '9700'
  },
  taxLabel: '',
  user: {
    email: 'test_user@vitalsource.com',
    hasSavedPayment: true
  }
}

const expressCheckout = createSlice({
  extraReducers,
  initialState,
  name: SLICES.expressCheckout,
  reducers,
})

export const { actions } = expressCheckout
export default expressCheckout
