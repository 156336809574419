import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ReduxProvider from '@/components/ReduxProvider'
import { actions } from '@/reducers/asset'
import { isShowingQuantityInputOnPDPSelector } from
    "@/reducers/miniCart/selectors"
import LocalePrice from '@/utils/factory/price/localePrice'
import variantFactory from '@/utils/factory/ssr/variant/variantFactory'

import Label from './Label'
import Radio from './Radio'

const Variants = ({
  currency,
  isZeroDecimalCurrency,
  taxes: { flatTaxMultiplier, taxMultiplier },
  translations: { common_no_price_product },
  variantsProps: {
    isOnlineResource,
    isOnlineResourceBundle,
    isSubscription,
    selectedVariant,
    translations: {
      purchase_buy_etextbook,
      purchase_purchase_item
    },
    variantOrder,
    variants
  }
}) => {

  const selectedVariantState = useSelector(state => state.asset.selectedVariant)
  const isItemInCart = useSelector(isShowingQuantityInputOnPDPSelector)
  const [
    serverSelectedVariant,
    setServerSelectedVariant
  ] = useState(selectedVariant)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.updateSelectedVariant(selectedVariant))
    dispatch(actions.hydrateVariants(variants))
  }, [])

  useEffect(() => {
    setServerSelectedVariant(selectedVariantState)
  }, [selectedVariantState])

  return variantOrder.map(id => {
    const currentVariant = variants[id]

    const localePrice = new LocalePrice({
      currency,
      flatTaxMultiplier,
      isZeroDecimalCurrency,
      options: {
        hideCurrencyLabel: true
      },
      price: currentVariant.studentPrice,
      taxMultiplier,
      translations: { common_no_price_product }
    })

    const isBulkIntegrationEnabled = useSelector(
      state => state.featureFlags.bulkIntegrationEnabled
    )

    const variant = variantFactory.createVariant(
      'PricingPanelVariant',
      {
        id,
        isOnlineResource,
        isOnlineResourceBundle,
        localePrice,
        selectedVariantId: serverSelectedVariant.id,
        translations: {
          purchase_buy_etextbook,
          purchase_purchase_item
        },
        variant: currentVariant
      }
    )

    return (
      <div
        className={variant.className}
        key={id}
      >
        <Radio
          disabled={!isItemInCart && isBulkIntegrationEnabled}
          variant={variant}
        />
        <Label
          isSubscription={isSubscription}
          variant={variant}
        />
      </div>
    )
  })
}

const Component = ({
  currency, isZeroDecimalCurrency, taxes, translations, variantsProps
}) => (
  <ReduxProvider>
    <Variants {...{
      currency,
      isZeroDecimalCurrency,
      taxes,
      translations,
      variantsProps
    }}
    />
  </ReduxProvider>
)

export default Component

Component.propTypes = {
  currency: PropTypes.object,
  isZeroDecimalCurrency: PropTypes.bool.isRequired,
  taxes: PropTypes.object,
  translations: PropTypes.object,
  variantsProps: PropTypes.shape({
    isSubscription: PropTypes.bool,
    selectedVariantId: PropTypes.number,
    variantOrder: PropTypes.array.isRequired,
    variants: PropTypes.object
  })

}
