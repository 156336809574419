import PropTypes from 'prop-types'

import { translate } from '@/utils/translations'

const BulkStorePdpLink = ({ bulkStoreUrl }) => {

  return (
    <a
      className='bulk-purchase-options'
      href={bulkStoreUrl}
    >
      {translate('purchase.bulk_purchase_options')}
    </a>
  )
}

export default BulkStorePdpLink

BulkStorePdpLink.propTypes = { bulkStoreUrl: PropTypes.string.isRequired }
