import { createSelector } from '@reduxjs/toolkit'

import {
  assetLineItemSelector,
  canExtendLicenseSelector,
  isCurrentRegionUsingFlatTaxSelector
} from '@/selectors'
import { translate } from '@/utils/translations'

export const isMiniCartEnabledSelector = createSelector(
  [state => state.miniCart.isEnabled],
  isEnabled => isEnabled
)

export const miniCartButtonStyleSelector = createSelector(
  [ assetLineItemSelector ],
  hasCurrentAssetAsLineItem => {
    const hasOutlineStyle = hasCurrentAssetAsLineItem
      ? 'vst-btn--orange-outline'
      : 'vst-btn--orange'

      return [
        'vst-btn--transparent',
        hasOutlineStyle,
        'add_to_cart',
        'vst-btn--large-full-width'
      ].join(' ')
    }
)

const currentRegionWarningButtonTextSelector = createSelector(
  [canExtendLicenseSelector],
  canExtendLicense => {
    const key = canExtendLicense ? 'extend_license' : 'to_cart'

    return translate(`carts.add_${key}`)
  }
)

export const addToCartButtonTextSelector = createSelector(
  [canExtendLicenseSelector],
  canExtendLicense=> {
    const key = canExtendLicense ? 'extend_license' : 'to_cart'

    return translate(`carts.add_${key}`)
  }
)

const lineItemAddedTextSelector = createSelector(
  [canExtendLicenseSelector],
  canExtendLicense => {
    const key = canExtendLicense ? 'license' : 'item'

    return translate(`carts.${key}_added_view_cart`)
  }
)

export const miniCartButtonTextSelector = createSelector(
  [
    assetLineItemSelector,
    state => state.asset.hasCurrentRegionWarning,
    currentRegionWarningButtonTextSelector,
    addToCartButtonTextSelector,
    lineItemAddedTextSelector
  ],
  (
    hasCurrentAssetAsLineItem,
    currentRegionWarning,
    currentRegionWarningText,
    addToCartButtonText,
    lineItemAddedText
  ) => {
    if (hasCurrentAssetAsLineItem) {
      return lineItemAddedText
    } else if (currentRegionWarning) {
      return currentRegionWarningText
    } else {
      return addToCartButtonText
    }
  }
)

export const miniCartItemsCountTextSelector = createSelector(
  [state => state.cart.itemsTotalCount],
  count => {
    const itemText = count > 1
      ? translate('carts.items')
      : translate('carts.item')

    return `${translate('common.cart')}: ${count} ${itemText}`
  }
)

export const miniCartTotalSelector = createSelector(
  [
    state => state.cart.formattedSubTotal,
    state => state.cart.total,
    state => state.currentRegion.storeCurrency,
    isCurrentRegionUsingFlatTaxSelector
  ], (subTotal, total, currencyLabel, regionUsesFlatTax) => {
    if (regionUsesFlatTax) {
      return `${total} ${currencyLabel}`
    } else {
      return `${subTotal} ${currencyLabel}`
    }
  }
)

export const isShowingQuantityInputOnPDPSelector = createSelector(
  [
    state => state.miniCart.isEnabled,
    assetLineItemSelector
  ], (isEnabled, hasItemInCart) => {
    if(isEnabled) {
      return !hasItemInCart
    } else {
      return true
    }
  }
)
