import PricingPanelVariant from './pricingPanelVariant'

const Variant = { PricingPanelVariant }

export default {
  createVariant(type, attributes) {
    const VariantType = Variant[type]

    return new VariantType(attributes)
  }
}
