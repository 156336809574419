import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  arrowImage: 'hidden',
  demoImage: 'default',
  wlsb2bLink: ''
}

const whiteLabelStoreDemo = createSlice({
  extraReducers,
  initialState,
  name: SLICES.whiteLabelStoreDemo,
  reducers
})

export const { actions } = whiteLabelStoreDemo
export default whiteLabelStoreDemo
