import { applyMiddleware, compose, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'

import rootReducer from '../reducers'
import gtmMiddleware from './google_tag_manager/middleware'

const initialState = {}

const middleware = [ReduxThunk]
if (window.dataLayer) {
  middleware.unshift(gtmMiddleware)
}

let composeEnhancers

const actAsProd = ['production', 'test'].includes(process.env.RAILS_ENV)
  || process.env.NODE_ENV === `production`

if (!actAsProd) {
  /* eslint-disable no-underscore-dangle */
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
   /* eslint-enable */
} else {
  composeEnhancers = compose
}

const enhancers = composeEnhancers(
  applyMiddleware(...middleware)
)

const testStore = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(ReduxThunk))
)

const store = createStore(
  rootReducer,
  initialState,
  enhancers
)

export default process.env.NODE_ENV === `test` ? testStore : store
