import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  details: [],
  isCalculated: false,
  isCalculating: false,
  label: '',
  total: 0,
  vatTaxMessage: ''
}

const taxes = createSlice({
  extraReducers,
  initialState,
  name: SLICES.taxes,
  reducers: reducers
})

export const { actions } = taxes
export default taxes
