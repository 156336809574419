import licenseInformationFactory from
'@/utils/factory/licenseInformation/licenseInformationFactory'

class LicenseInformation {
  constructor({
    daysLeftInLicense,
    duration,
    durationInDays,
    isOnlineResource,
    isSubscription
  }) {
    this.expiration = licenseInformationFactory.createLicense(
      'LicenseExpiration', {
        daysLeftInLicense, duration, durationInDays, isOnlineResource
      }
    )
    this.duration = licenseInformationFactory.createLicense('LicenseDuration', {
      duration, isOnlineResource, isSubscription
    })
  }
}

export default LicenseInformation
