require('./index.scss')

import { useState } from 'react'

import Dialog from '@/components/Dialog'
import MiniCartAddToCartButton from '@/components/MiniCartAddToCartButton'
import { translate } from '@/utils/translations'

import AddToCartButton from './components/AddToCartButton'
import CloseButton from './components/CloseButton'
import DialogText from './components/DialogText'

const SalesRightsWarningModal = () => {
  const [isShowing, setIsShowing] = useState(false)

  return (
    <Dialog
      heading={translate('carts.confirm_add_to_cart')}
      id='sales-rights-warning-modal'
      isShowing={isShowing}
      onChangeShow={setIsShowing}
      trigger={<AddToCartButton onClick={() => setIsShowing(true)} />}
    >
      <DialogText />
      <div className='row u-push-half--bottom'>
        <MiniCartAddToCartButton onClick={() => setIsShowing(false)} />
      </div>
      <CloseButton onClick={() => setIsShowing(false)} />
    </Dialog>
  )
}

export default SalesRightsWarningModal
