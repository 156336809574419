import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  miniCartButtonStyleSelector,
  miniCartButtonTextSelector
} from '@/selectors'

const AddedItemToMiniCartButton = ({ onClick }) => {
  const miniCartButtonStyles = useSelector(miniCartButtonStyleSelector)
  const miniCartButtonText = useSelector(miniCartButtonTextSelector)

  return (
    <button
      className={miniCartButtonStyles}
      onClick={onClick}
    >
      {miniCartButtonText}
    </button>
  )
}

export default AddedItemToMiniCartButton

AddedItemToMiniCartButton.propTypes = { onClick: PropTypes.func.isRequired }
