import PropTypes from 'prop-types'

import { translate } from '@/utils/translations'

import { copyToClipboard } from './helpers'

const CopyButton = ({ id }) => (
  <button
    className='btn vst-btn--ocean vst-btn--copy'
    data-testid='copy-button'
    name='copyButton'
    onClick={() => copyToClipboard({ id })}
    type='button'
  >
    <img
      alt=''
      className='u-soft-half--right align-text-bottom'
      src={require('/app/assets/images/copy-icon.svg')}
    />
    {translate('common.copy')}
  </button>

)

export default CopyButton

CopyButton.propTypes = {
  id: PropTypes.string.isRequired
}
