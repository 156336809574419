import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const FIELDS = [
  'admin',
  'queue',
  'features',
  'imports',
  'emailDashboard',
  'publishers',
  'roleSwitcher',
  'search',
  'support'
]

const initialState = {}

const adminPermissions = createSlice({
  extraReducers: extraReducers,
  initialState,
  name: SLICES.adminPermissions,
  reducers: reducers
})

export const { actions } = adminPermissions
export default adminPermissions
