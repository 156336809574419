import camelize from 'camelize'

import { logError } from '@/utils/honeybadger'

export const handle = (error, options) => {
  logError(error, options)

  if (error?.response?.data?.errors) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    throw {
      error: camelize(error.response.data.errors),
      responseData: camelize(error.response.data),
      status: error.response.status
    }
  } else if (error?.response?.data?.error) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    throw {
      error: error.response.data.error,
      responseData: camelize(error.response.data),
      status: error.response.status
    }
  } else if (error.response) {
    throw {
      error: camelize(error.response),
      status: error.response.status
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    throw {
      error: error,
      status: null
    }
  }
}
