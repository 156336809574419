require('./index.scss')

import { useEffect, useState } from 'react'

import ReduxProvider from '@/components/ReduxProvider'
import { translate } from '@/utils/translations'

import SkeletonThemeWrapper from '../SkeletonThemeWrapper'
import Modal from './Modal'

const CarbonNeutralityDialog = () => {
  const key = 'pages.store_home.carbon_neutrality'

  const [isShowing, setIsShowing] = useState(false)
  const [isShowingComponent, setIsShowingComponent] = useState(false)

  useEffect(() => {
    setIsShowingComponent(true)
  })

  return (
    <Choose>
      <When condition={isShowingComponent}>
        <div id='carbon-neutrality'>
          <button
            className='vst-btn--transparent styled-as-link'
            onClick={() => setIsShowing(true)}
            type='button'
          >
            <div id='carbon-neutrality-modal-trigger'>
              <div className='flower' />
              {translate(`${key}.planet_smart`)}
            </div>
            <Modal
              isShowing={isShowing}
              onChangeShow={setIsShowing}
            />
          </button>
          <p className='u-align--center' >
            {translate('pages.store_home.carbon_neutrality.purchase')}
          </p>
        </div>
      </When>
      <Otherwise>
        <SkeletonThemeWrapper count={1} />
      </Otherwise>
    </Choose>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = () => (
  <ReduxProvider>
    <CarbonNeutralityDialog />
  </ReduxProvider>
)
export default Component
