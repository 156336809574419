import { routeFor } from '@/utils/router'
import { translate } from '@/utils/translations'
const ProceedToCheckout = () => (
  <a
    className='vst-btn--large-full-width vst-btn--orange'
    href={routeFor('checkout')}
  >
    {translate('carts.proceed_to_checkout')}
  </a>
)

export default ProceedToCheckout
