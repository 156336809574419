import { useSelector } from 'react-redux'

import AddToCartButton from '@/components/PDP/PricingPanel/AddToCartButton'

import BulkStoreAddToCartButton from './BulkStoreAddToCartButton'

const ActionButton = () => {
  const isBulkEnabled = useSelector(state => state.currentRegion.bulkEnabled)

  return isBulkEnabled ? <BulkStoreAddToCartButton /> : <AddToCartButton />
}

export default ActionButton
