import { isObject } from '@/utils/typeCheck'

export const mergeKeys = (state, mergeState) => {
  const updatedKeys = Object.keys(mergeState).reduce((newState, key) => {
    if (isObject(mergeState[key])) {
      newState[key] = { ...state[key], ...mergeState[key] }
    } else {
      newState[key] = mergeState[key]
    }
    return newState
  }, {})

  return { ...state, ...updatedKeys }
}
