require('./index.scss')

import PropTypes from 'prop-types'
import { useState } from 'react'

import Dialog from '@/components/Dialog'

import AssetMetadata from '../AssetMetadataCard'
import AccessCode from './components/AccessCode'
import ContinueButton from './components/ContinueButton'

const OnlineResourceModal = ({
  heading,
  imageUrl = null,
  isbn,
  isShowing,
  kind,
  onChangeShow,
  title,
  trigger
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const isShowingAssetMetadata = imageUrl && title && isbn && kind

  return (
    <Dialog
      heading={heading || title}
      id='online-resource-modal'
      isShowing={isShowing}
      onChangeShow={onChangeShow}
      theme='ocean'
      trigger={trigger}
    >
      <If condition={isShowingAssetMetadata}>
        <div className='card metadata-wrapper'>
          <AssetMetadata {...{ imageUrl, isbn, kind, title }} />
        </div>
      </If>
      <Choose>
        <When condition={isLoaded}>
          <AccessCode {...{
            imageUrl,
            isbn,
            kind,
            title
          }}
          />
        </When>
        <Otherwise>
          <ContinueButton
            isLoaded={isLoaded}
            isbn={isbn}
            setIsLoaded={setIsLoaded}
          />
        </Otherwise>
      </Choose>
    </Dialog>
  )
}

export default OnlineResourceModal

OnlineResourceModal.propTypes = {
  heading: PropTypes.string,
  imageUrl: PropTypes.string,
  isShowing: PropTypes.bool.isRequired,
  isSubscription: PropTypes.bool,
  isbn: PropTypes.string,
  kind: PropTypes.string,
  onChangeShow: PropTypes.func.isRequired,
  price: PropTypes.string,
  title: PropTypes.string.isRequired,
  trigger: PropTypes.element
}
