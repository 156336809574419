import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = {
  errors: null,
  isUpdating: false,
  suites: {
    failing: [],
    passing: []
  },
  summary: {
    passed: true,
    suites: "0 failing suites out of 0",
    tests: "0 failing tests out of 0"
  }
}

const ghostInspector = createSlice({
  extraReducers,
  initialState,
  name: SLICES.ghostInspector,
  reducers
})

export const { actions } = ghostInspector
export default ghostInspector
