require('./index.scss')

import PropTypes from 'prop-types'

import ReadNowButton from '@/components/PDP/ReadNowButton'

const ReadNowBanner = ({
  readNowBannerProps: {
    isShowing,
    translations: { product_display_already_purchased }
  },
  readNowButtonProps
}) => (
  <If condition={isShowing}>
    <div className='bookshelf-banner'>
      <span>{product_display_already_purchased}</span>
      <ReadNowButton {...{ ...readNowButtonProps }} />
    </div>
  </If>
)

export default ReadNowBanner

ReadNowBanner.propTypes = {
  readNowBannerProps: PropTypes.shape({
    isShowing: PropTypes.bool.isRequired,
    translations: PropTypes.shape({
      product_display_already_purchased: PropTypes.string.isRequired
    })
  }),
  readNowButtonProps: PropTypes.shape({
    isAcrobatiq: PropTypes.bool.isRequired,
    isBundle: PropTypes.bool.isRequired,
    isGuestReferenceUser: PropTypes.bool.isRequired,
    isOnlineResource: PropTypes.bool.isRequired,
    isShowingCustomerSupportUrl: PropTypes.bool.isRequired,
    isShowingExpirationStatus: PropTypes.bool.isRequired,
    readNowExpirationDays: PropTypes.string.isRequired,
    readNowUrl: PropTypes.string.isRequired,
    supportUrl: PropTypes.string.isRequired,
    translations: PropTypes.shape({
      common_access_code_send_by_email: PropTypes.string.isRequired,
      common_customer_support: PropTypes.string.isRequired,
      common_read_in_bookshelf: PropTypes.string.isRequired
    })
  })
}
