import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.relatedAssets]
})

const replaceState = (_state, { payload }) => payload[SLICES.relatedAssets]

export const updateAssetSelectedVariant = (
  state, { payload: { assetId, variant } }
) => ({
    ...state,
    assets: {
      ...state.assets,
      [assetId]: { ...state.assets[assetId], selectedVariant: variant }
    }
})

export const reducers = { updateAssetSelectedVariant }
export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
  [SHARED_ACTIONS.lineItems.createSuccess]: replaceState,
  [SHARED_ACTIONS.lineItems.destroySuccess]: replaceState,
}
