import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state,
  ...payload[SLICES.featureFlags]
})

export const reducers = { }

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
