import loadable from '@loadable/component'
import PropTypes from 'prop-types'

const LoadableLibrary = loadable.lib(() => import('react-markdown'))

const MarkdownReader = ({ children }) => (
  <LoadableLibrary>
    {({ default: ReactMarkdown }) => <ReactMarkdown>{children}</ReactMarkdown>}
  </LoadableLibrary>
)

export default MarkdownReader

MarkdownReader.propTypes = {
  children: PropTypes.string
}
