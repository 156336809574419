import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.recaptcha]
})

const setSuccessfulResponse = (state, { payload }) => {
  return ({
    ...state,
    response: payload
  })
}

const setExpiredResponse = state => ({
  ...state,
  response: 'expired'
})

export const reducers = {
  setExpiredResponse,
  setSuccessfulResponse
 }

 export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
