/*eslint-disable react/no-danger*/
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Dialog from '@/components/Dialog'
import { translate } from '@/utils/translations'

const buttonClass = [
  'u-soft-half',
  'u-weight--normal',
  'vst-btn--large-300',
  'vst-btn--orange'
].join(' ')

const GiftModal = ({ isShowing, onChangeShow }) => {
  const bulkStoreUrl = useSelector(state => state.asset.bulkStoreUrl)

  return (
    <Dialog
      heading={translate('assets.show.purchase_as_gift.link')}
      id='gift-modal'
      isShowing={isShowing}
      onChangeShow={onChangeShow}
    >
      <div className='content'>
        <span
          dangerouslySetInnerHTML={{
            __html: translate('assets.show.purchase_as_gift.modal')
          }}
        />
        <div className='link'>
          <a
            className={buttonClass}
            data-testid='bulk-store-purchase-options-link'
            href={bulkStoreUrl}
          >
            {translate('assets.show.purchase_as_gift.link')}
          </a>
        </div>
      </div>
    </Dialog>
  )
}

export default GiftModal

GiftModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onChangeShow: PropTypes.func.isRequired
}
