import { createSlice } from '@reduxjs/toolkit'

import { FORM_STEPS, STRIPE_STATES } from '@/constants/checkout'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  activeStep: FORM_STEPS.choosePayment,
  chosenPaymentType: null,
  countrySelectOptions: [],
  countrySubdivisions: [],
  creditCardPaymentGateway: null,
  currencyMultiplier: 100,
  errors: null,
  failedAttemptLimit: 3,
  failedAttempts: 0,
  hasSavedPayment: false,
  hasSolvedRecaptcha: false,
  hasSubmittedCardForm: false,
  isApplePayEnabled: false,
  isCreditCardDisabled: false,
  isForcingRecaptcha: false,
  isGuestReferenceUser: false,
  isInternationalPurchasingEnabled: false,
  isPaymentInfoEditable: false,
  isPromoCodeEnabled: false,
  isSavedPaymentEnabled: false,
  isSavingCustomerPaymentMethod: false,
  isShowingRecaptchaModal: false,
  isStripeWalletsEnabled: false,
  paymentGateways: [],
  paypalData: null,
  paypalSettings: {},
  pendingPurchaseTransactionUuid: null,
  publicInvisibleRecaptchaKey: '',
  publicRecaptchaKey: '',
  savedBillingDetails: {},
  savedCardDetails: {},
  showPaypalOption: false,
  showSpinner: false,
  stripeErrors: [],
  stripePaymentIntent: {},
  stripePaymentIntentClientSecret: '',
  stripePaymentMethod: {},
  stripeState: STRIPE_STATES.initial,
  translations: {},
  userEmail: '',
  validatePostalCode: true
}

const checkout = createSlice({
  extraReducers,
  initialState,
  name: 'checkout',
  reducers
})

export const { actions } = checkout
export default checkout
