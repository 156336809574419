import CartLicenseExpiration from
  '@/utils/factory/cartLicenseInformation/cartLicenseExpiration'

import LicenseDuration from './licenseDuration'
import LicenseExpiration from './licenseExpiration'

const InformationType = {
  CartLicenseExpiration,
  LicenseDuration,
  LicenseExpiration
}

export default {
  createLicense(type, attributes) {
    const LicenseInformationType = InformationType[type]

    return new LicenseInformationType(attributes)
  }
}
