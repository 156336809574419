import { QUANTITY_INPUT } from '@/constants/quantity_input'

export const isValidQuantity = ({ bulkQuantityLimit, quantity }) => {
  const quantityIsPositive = quantity > 0
  const quantityIsInteger = quantity % 1 === 0
  const quantityLessThanLimit = quantity <= bulkQuantityLimit

  return quantityIsPositive && quantityIsInteger && quantityLessThanLimit
}

export function handleQuantityChange(value, setValue) {
  const MIN_VALUE = QUANTITY_INPUT.min_value
  const MAX_VALUE = QUANTITY_INPUT.max_value

  let numValue = parseInt(value, 10)

  if (numValue === '' || numValue === 0 || isNaN(numValue)) {
    setValue(1)
    return
  }

  if (/^\d{0,3}$/.test(value)) {
    if (numValue >= MIN_VALUE && numValue <= MAX_VALUE) {
      setValue(numValue)
    }
  }
}
