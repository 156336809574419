import PropTypes from 'prop-types'

import ReadNow from '@/components/ReadNow'
import Total from '@/components/Total'

const SubscriptionAssetPurchasedCard = ({
  currency,
  isZeroDecimalCurrency,
  readNowButtonProps,
  taxes,
  title,
  total,
}) => {

  return (
    <div className='asset-purchased card'>
      <legend className='title'>
        {title}
      </legend>

      <Total {...{
        currency,
        isZeroDecimalCurrency,
        taxes,
        total
      }}
      />

      <ReadNow {...{
        ...readNowButtonProps
      }}
      />
    </div>
  )
}

export default SubscriptionAssetPurchasedCard

SubscriptionAssetPurchasedCard.propTypes = {
  currency: PropTypes.shape({
    label: PropTypes.string.isRequired,
    showing: PropTypes.bool.isRequired,
    storeCurrency: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired
  }),
  isZeroDecimalCurrency: PropTypes.bool.isRequired,
  readNowButtonProps: PropTypes.shape({
    isAcrobatiq: PropTypes.bool.isRequired,
    isBulkIntegrationEnabled: PropTypes.bool.isRequired,
    isBundle: PropTypes.bool.isRequired,
    isGuestReferenceUser: PropTypes.bool.isRequired,
    isOnlineResource: PropTypes.bool.isRequired,
    isShowingCustomerSupportUrl: PropTypes.bool.isRequired,
    isShowingExpirationStatus: PropTypes.bool.isRequired,
    readNowExpirationDays: PropTypes.string.isRequired,
    readNowUrl: PropTypes.string.isRequired,
    supportUrl: PropTypes.string.isRequired,
  }),
  taxes: PropTypes.shape({
    flatTaxMultiplier: PropTypes.number,
    taxMultiplier: PropTypes.number,
  }),
  title: PropTypes.string.isRequired,
  total: PropTypes.object.isRequired,
}
