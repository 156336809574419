import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Tooltip from '@/components/Tooltip'
import {
  hasSelectedVariantSavingsSelector,
  printListPriceSelector,
  savingsPriceSelector
} from '@/selectors'
import { translate } from '@/utils/translations'

import ReduxProvider from '../ReduxProvider'
import SkeletonThemeWrapper from '../SkeletonThemeWrapper'
import Icon from '../Tooltip/Icon'

const Savings = ({ isShowingSavings }) => {
  const price = useSelector(savingsPriceSelector)
  const printListPrice = useSelector(printListPriceSelector)
  const [isRenderingComponent, setisRenderingComponent] = useState(false)

  const selectedVariantHasSavings
    = useSelector(hasSelectedVariantSavingsSelector)

  useEffect(() => {
    if (selectedVariantHasSavings && price) setisRenderingComponent(true)
  }, [selectedVariantHasSavings, price])

  const savingsTooltipText = ({ price, printListPrice }) => (
    [
      `${translate('purchase.print_list_pricing_is')}`,
      `${printListPrice}.`,
      translate('purchase.purchasing_with_vitalsource_saves_you'),
      `${price}.`
    ].join(' ')
  )

  return (

    <If condition={isShowingSavings && selectedVariantHasSavings}>
      <Choose>
        <When condition={isRenderingComponent}>
          <div className='d-flex justify-content-center savings-wrap'>
            {translate('purchase.savings')}
            {' '}
            {price}
            <Tooltip
              ariaText={translate('tooltips.alt_text.savings')}
              tooltipText={savingsTooltipText({ price, printListPrice })}
            >
              <Icon />
            </Tooltip>
          </div>
        </When>
        <Otherwise>
          <SkeletonThemeWrapper count={1} />
        </Otherwise>
      </Choose>
    </If>

  )
}

/*eslint-disable react/no-multi-comp*/
const Component = ({ isShowingSavings }) => (
  <ReduxProvider>
    <Savings {...{ isShowingSavings }} />
  </ReduxProvider>
)

export default Component

Component.propTypes = {
  isShowingSavings: PropTypes.bool.isRequired
}

Savings.propTypes = {
  isShowingSavings: PropTypes.bool.isRequired
}
