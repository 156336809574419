import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.user]
})

const isLicenseValid = (state, { payload }) => ({
  ...state,
  library: {
    ...state.library,
    isLicenseValid: payload
  }
})

const isLicenseExpired = (state, { payload }) => ({
  ...state,
  library: {
    ...state.library,
    isLicenseExpired: payload
  }
})

export const reducers = {
  isLicenseExpired,
  isLicenseValid
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
