import { createSlice } from '@reduxjs/toolkit'

import { reducers } from './reducers'

export const initialState = {
  eventKey: '',
  isShowingReadMore: false
}

const accordion = createSlice({
  initialState,
  name: 'accordion',
  reducers
})

export const { actions } = accordion
export default accordion
