import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  assets: {}
}

const relatedAssets = createSlice({
  extraReducers,
  initialState,
  name: SLICES.relatedAssets,
  reducers
})

export const { actions } = relatedAssets
export default relatedAssets
