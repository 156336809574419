import { PERPETUAL } from '@/constants/durations'
import { translate } from '@/utils/translations'

import priceFactory from "../price/priceFactory"

class PricingPanelVariant {
  constructor({
    id,
    selectedVariantId,
    variant
  }) {
    const { duration, studentPrice } = variant

    const isSelected = id === selectedVariantId

    const isPerpetual = duration === PERPETUAL

    const durationExpirationText = () => {
      if (isPerpetual) {
        return translate('purchase.buy_eTextbook')
      }
      else {
        return duration
      }
    }

    this.id = id,
    this.studentPrice = priceFactory.createPrice(
      'StudentPrice', { studentPrice }
    ).formattedAmount
    this.durationExpirationText = durationExpirationText()
    this.className = `variant${isSelected ? ' selected' : ''}`,
    this.fullVariant = variant
    this.isSelected = isSelected
  }
}

export default PricingPanelVariant
