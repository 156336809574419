import camelize from 'camelize'

import { FORM_STEPS, PAYMENT_TYPES, STRIPE_STATES } from '@/constants/checkout'
import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.checkout]
})

const updateStripePaymentIntent = (state, { payload }) => ({
  ...state,
  stripePaymentIntentClientSecret: payload
})

const switchToChoosePaymentStep = state => ({
  ...state,
  activeStep: FORM_STEPS.choosePayment,
  chosenPaymentType: null,
  paypalData: null,
  pendingPurchaseTransactionUuid: null,
  stripeErrors: [],
  watchmanUserBalance: null,
})

const switchToEditBillingAddressStep = state => ({
  ...state,
  activeStep: FORM_STEPS.editBillingAddressStep,
  chosenPaymentType: null,
  isEditingBillingAddress: true,
  paypalData: null,
  pendingPurchaseTransactionUuid: null,
  stripeErrors: [],
  watchmanUserBalance: null,
})

const switchToBillingAddressStep = state => ({
  ...state,
  activeStep: FORM_STEPS.billingAddress,
  chosenPaymentType: state.creditCardPaymentGateway,
  pendingPurchaseTransactionUuid: null,
  stripeErrors: []
})

const switchToBillingAddressStepV2 = state => ({
  ...state,
  activeStep: FORM_STEPS.billingAddress,
  chosenPaymentType: state.creditCardPaymentGateway,
  pendingPurchaseTransactionUuid: null,
  stripeErrors: []
})

const switchToPaymentInfoFormStep = state => ({
  ...state,
  chosenPaymentType: state.creditCardPaymentGateway,
  isPaymentInfoEditable: true,
  stripeErrors: []
})

const switchToPaymentInfoFormStepWithErrors = state => ({
  ...state,
  chosenPaymentType: state.creditCardPaymentGateway,
  isPaymentInfoEditable: true
})

const switchToSavedPaymentPreviewStep = state => ({
  ...state,
  activeStep: FORM_STEPS.savedPaymentPreview,
  chosenPaymentType: state.creditCardPaymentGateway,
  pendingPurchaseTransactionUuid: null,
  stripeErrors: []
})

const switchToFinancialAidConfirmationStep = state => ({
  ...state,
  activeStep: FORM_STEPS.financialAidConfirmPurchase
})

const switchToInstitutionPickerStep = state => ({
  ...state,
  activeStep: FORM_STEPS.institutionPicker
})

const switchToInstitutionPickerEditStep = state => ({
  ...state,
  activeStep: FORM_STEPS.institutionPickerEdit
})

const switchToPaypalConfirmationStep = state => ({
  ...state,
  activeStep: FORM_STEPS.paypalConfirmPurchase
})

const paypalActionStart = (state, _action) => ({
  ...state,
  showSpinner: true
})

const disableSavedPayment = state => ({
  ...state,
  activeStep: FORM_STEPS.choosePayment,
  hasSavedPayment: false,
  pendingPurchaseTransactionUuid: null,
  stripeErrors: []
})

const fetchCountrySubdivisions = {
  fetchCountrySubdivisionsFailure: (state, { payload }) => ({
    ...state,
    errors: payload
  }),
  fetchCountrySubdivisionsStart: (state, _action) => ({
    ...state,
    countrySubdivisions: [],
    errors: null
  }),
  fetchCountrySubdivisionsSuccess: (state, { payload }) => ({
    ...state,
    countrySubdivisions: payload
  })
}

const getWatchmanUserBalance = {
  getWatchmanUserBalanceFailure: (state, { payload }) => ({
    ...state,
    errors: payload,
    showSpinner: false
  }),
  getWatchmanUserBalanceStart: (state, _action) => ({
    ...state,
    errors: null,
    showSpinner: true
  }),
  getWatchmanUserBalanceSuccess: (state, { payload }) => {
    return ({
      ...state,
      chosenPaymentType: PAYMENT_TYPES.watchman,
      orderTotal: payload.totalBeforeTax,
      pendingPurchaseTransactionUuid: payload.pendingPurchaseTransactionUuid,
      showSpinner: false,
      watchmanUserBalance: payload.userBalance,
      watchmanUserBalanceLeft: payload.userBalanceLeft,
    })
  }
}

const payWithWatchmanBalance = {
  payWithWatchmanBalanceFailure: (state, { payload }) => ({
    ...state,
    activeStep: FORM_STEPS.choosePayment,
    chosenPaymentType: null,
    errors: payload,
    showSpinner: false,
    watchmanUserBalance: null
  }),
  payWithWatchmanBalanceStart: (state, _action) => ({
    ...state,
    errors: null,
    showSpinner: true
  }),
  payWithWatchmanBalanceSuccess: (state, { payload }) => {
    return ({
      ...state,
      chosenPaymentType: PAYMENT_TYPES.watchman,
      orderTotal: payload.totalBeforeTax,
      showSpinner: false,
      watchmanUserBalance: payload.userBalance,
      watchmanUserBalanceLeft: payload.userBalanceLeft
    })
  }
}

const updatePendingPurchaseInstitution = {
  updatePendingPurchaseInstitutionFailure: (state, { payload }) => ({
    ...state,
    errors: payload,
    showSpinner: false
  }),
  updatePendingPurchaseInstitutionStart: (state, _action) => ({
    ...state,
    errors: null,
    showSpinner: true
  }),
  updatePendingPurchaseInstitutionSuccess: (state, _action) => {
    let activeStep
    if (state.chosenPaymentType === PAYMENT_TYPES.paypal) {
      activeStep = FORM_STEPS.paypalConfirmPurchase
    } else if (state.chosenPaymentType === PAYMENT_TYPES.applepay) {
      activeStep = FORM_STEPS.choosePayment
    } else (
      console.error(`Can't handle ${state.chosenPaymentType} payment type.`)
    )

    return ({
      ...state,
      activeStep,
      showSpinner: false
    })
  }
}

const paypalCreateStart = (state, _action) => state

const paypalError = (state, { payload }) => ({
  ...state,
  errors: payload,
  showSpinner: false
})

const paypalAuthorize = {
  paypalAuthorizeStart: paypalActionStart,
  paypalAuthorizeSuccess: (state, { payload }) => {
    const { errors, skipInstitutionPicker, paypalData } = camelize(payload)
    const nextStep = skipInstitutionPicker
      ? FORM_STEPS.paypalConfirmPurchase
      : FORM_STEPS.institutionPicker

    return ({
      ...state,
      activeStep: nextStep,
      chosenPaymentType: PAYMENT_TYPES.paypal,
      errors,
      paypalData,
      showSpinner: false
    })
  }
}

const paypalExecute = {
  paypalExecuteStart: paypalActionStart,
  paypalExecuteSuccess: (state, _action) => state
}

const applePayInstitutionRequired = (state, _action) => ({
  ...state,
  activeStep: FORM_STEPS.institutionPicker,
  chosenPaymentType: PAYMENT_TYPES.applepay,
  errors: [],
  showSpinner: false
})

const updateBillingAddressSuccess = (state, { payload }) => ({
  ...state,
  activeStep: FORM_STEPS.creditCardPayment,
  isForcingRecaptcha: payload.triggerRecaptcha,
  pendingPurchaseTransactionUuid: payload.pendingPurchaseTransactionUuid,
  stripePaymentIntentClientSecret: payload.paymentIntentClientSecret
})

const updateAddressSuccessV2 = (state, { payload }) => ({
  ...state,
  activeStep: FORM_STEPS.creditCardPayment,
  isForcingRecaptcha: payload.triggerRecaptcha,
  pendingPurchaseTransactionUuid: payload.pendingPurchaseTransactionUuid
})

const addStripeErrors = (state, { payload }) => ({
  ...state,
  stripeErrors: payload || [],
  stripeState: STRIPE_STATES.initial
})

const clearStripeErrors = state => ({
  ...state,
  stripeErrors: []
})

const handleStripeSuccess = state => ({
  ...state,
  stripeState: STRIPE_STATES.authorizedPayment
})

const createStripePaymentMethod = {
  createStripePaymentMethodFailure: addStripeErrors,
  createStripePaymentMethodStart: state => ({
    ...state,
    stripeErrors: [],
    stripeState: STRIPE_STATES.processing
  }),

  createStripePaymentMethodSuccess: (state, { payload }) => ({
    ...state,
    stripePaymentMethod: payload.paymentMethod,
    stripeState: STRIPE_STATES.createdPaymentMethod
  })
}

const authorizeStripePayment = {
  authorizeStripePaymentFailure: addStripeErrors,
  authorizeStripePaymentSuccess: (state, { payload }) => {
    if(payload.requiresAction) {
      return({
        ...state,
        stripePaymentIntentClientSecret: payload.paymentIntentClientSecret,
        stripeState: STRIPE_STATES.handlingCardAction
      })
    } else {
      return handleStripeSuccess(state)
    }
  }
}

const handleStripeCardAction = {
  handleStripeCardActionFailure: (state, { payload }) => ({
    ...state,
    stripeErrors: payload,
    stripeState: STRIPE_STATES.handledCardAction
  }),
  handleStripeCardActionSuccess: state => ({
    ...state,
    stripeState: STRIPE_STATES.handledCardAction
  })
}

const submitStripeCardActionResult = {
  submitStripeCardActionResultFailure: addStripeErrors,
  submitStripeCardActionResultSuccess: handleStripeSuccess
}

const setIsForcingRecaptcha = (state, { payload }) => ({
  ...state,
  isForcingRecaptcha: payload
})

const setHasSolvedRecaptcha = (state, { payload }) => ({
  ...state,
  hasSolvedRecaptcha: payload
})

const setIsShowingRecaptchaModal = (state, { payload }) => ({
  ...state,
  isShowingRecaptchaModal: payload
})

const recordCardFormSubmission = state => ({
  ...state,
  hasSubmittedCardForm: true
})

const updateSavePaymentMethod = ( state, { payload: { savingPayment } }) => ({
    ...state,
    isSavingCustomerPaymentMethod: savingPayment
})

const incrementFailedAttempts = ( state, { payload: { failedAttempts } }) => ({
    ...state,
    failedAttempts: failedAttempts + 1
})

export const reducers = {
  ...authorizeStripePayment,
  ...createStripePaymentMethod,
  ...fetchCountrySubdivisions,
  ...handleStripeCardAction,
  ...getWatchmanUserBalance,
  ...paypalAuthorize,
  ...paypalExecute,
  ...payWithWatchmanBalance,
  ...submitStripeCardActionResult,
  ...updatePendingPurchaseInstitution,
  addStripeErrors,
  applePayInstitutionRequired,
  clearStripeErrors,
  disableSavedPayment,
  handleStripeSuccess,
  incrementFailedAttempts,
  paypalCreateStart,
  paypalError,
  recordCardFormSubmission,
  setHasSolvedRecaptcha,
  setIsForcingRecaptcha,
  setIsShowingRecaptchaModal,
  switchToBillingAddressStep,
  switchToBillingAddressStepV2,
  switchToChoosePaymentStep,
  switchToEditBillingAddressStep,
  switchToFinancialAidConfirmationStep,
  switchToInstitutionPickerEditStep,
  switchToInstitutionPickerStep,
  switchToPaymentInfoFormStep,
  switchToPaymentInfoFormStepWithErrors,
  switchToPaypalConfirmationStep,
  switchToSavedPaymentPreviewStep,
  updateAddressSuccessV2,
  updateBillingAddressSuccess,
  updateSavePaymentMethod,
  updateStripePaymentIntent
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
  [SHARED_ACTIONS.billingAddress.updateSuccess]: updateBillingAddressSuccess,
  [SHARED_ACTIONS.billingAddress.updateSuccessV2]: updateAddressSuccessV2,
}
