import PropTypes from 'prop-types'

import Title from './Title'

const OrderSummary = ({ children }) => (
  <div className='express_checkout__order_summary'>
    <ul>
      <Title />
      {children}
    </ul>
  </div>
)

export default OrderSummary

OrderSummary.propTypes = {
  children: PropTypes.array.isRequired,
}
