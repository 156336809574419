import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = {}

const activeRegions = createSlice({
  extraReducers: extraReducers,
  initialState,
  name: SLICES.activeRegions,
  reducers: reducers
})

export const { actions } = activeRegions
export default activeRegions
