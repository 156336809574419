import LicenseInformation from '@/utils/factory/licenseInformation'
import licenseInformationFactory from
'@/utils/factory/licenseInformation/licenseInformationFactory'

class CartLicenseInformation extends LicenseInformation {
  constructor({
    daysLeftInLicense,
    duration,
    durationInDays,
    isOnlineResource,
    isSubscription
  }) {
    super({
      daysLeftInLicense,
      duration,
      durationInDays,
      isOnlineResource,
      isSubscription
    })

    this.expiration = licenseInformationFactory.createLicense(
      'CartLicenseExpiration', {
        daysLeftInLicense,
        duration,
        durationInDays,
        isOnlineResource,
        isSubscription
      }
    )
  }
}

export default CartLicenseInformation
