import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  isPending: false,
  newRecord: {
    isEditing: false,
    newValue: null
  },
  page: 1,
  rows: [],
  totalPages: 1,
}

const guestUsers = createSlice({
  extraReducers: extraReducers,
  initialState,
  name: SLICES.guestUsers,
  reducers: reducers
})

export const { actions } = guestUsers
export default guestUsers
