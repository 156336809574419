import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  bulkRequestStatus: null,
  isLoading: true,
  refund: {
    error: null,
    success: false
  },
  requestFailure: false,
  transactions: {}
}

const orderHistory = createSlice({
  extraReducers,
  initialState,
  name: SLICES.orderHistory,
  reducers
})

export const { actions } = orderHistory
export default orderHistory
