require('./index.scss')

import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

const SkeletonThemeWrapper = ({
  count,
  height,
  width
}) => (
  <Skeleton
    className='skeleton-loader'
    count={count}
    height={height}
    width={width}
  />
)

export default SkeletonThemeWrapper

SkeletonThemeWrapper.propTypes = {
  count: PropTypes.number,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}
