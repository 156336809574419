import { useState } from 'react'
import { useSelector } from 'react-redux'

import Dialog from '@/components/Dialog'
import {
  expressCheckoutDialogTitleSelector,
  isShowingExpressCheckoutSelector
} from '@/selectors'

import Content from './Content'
import Trigger from './Trigger'
const DIALOG_ID = 'express-checkout-dialog'

const ExpressCheckoutDialog = () => {
  const isExpressCheckoutEnabled = useSelector(
    isShowingExpressCheckoutSelector
  )

  const [isShowing, setIsShowing] = useState(false)

  const dialogTitle = useSelector(expressCheckoutDialogTitleSelector)

  return (
    <If condition={isExpressCheckoutEnabled}>
      <Dialog
        heading={dialogTitle}
        id={DIALOG_ID}
        isShowing={isShowing}
        onChangeShow={setIsShowing}
        trigger={<Trigger />}
      >
        <Content />
      </Dialog>
    </If>
  )
}

export default ExpressCheckoutDialog
