import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ExternalLinkIcon from '@/components/ExternalLinkIcon'
import { translate } from '@/utils/translations'

const buttonStyles = [
  'my-4',
  'vst-btn--medium',
  'vst-btn--ocean'
].join(' ')

const WebsiteButtonOcean = ({ isbn }) => {
  const loginUrl = useSelector(
    state => state.onlineResourceModal[isbn].loginUrl
  )

  return (
    <a
      className={buttonStyles}
      href={loginUrl}
      rel='noreferrer noopener'
      target='_blank'
    >
      {translate('order_history.button.go_to_website')}
      <ExternalLinkIcon theme='white' />
    </a>
  )
}

WebsiteButtonOcean.propTypes = { isbn: PropTypes.string.isRequired }

export default WebsiteButtonOcean
