import { createSelector } from '@reduxjs/toolkit'

import { translate } from '@/utils/translations'

const US_REGION = 'US'

export const displayStoreCurrencySelector = state =>
  state.currentRegion.storeCurrency || ''

export const isShowingBulkLinkSelector = createSelector(
  [
    state => state.currentRegion.bulkStoreKey,
    state => state.user.searchableRole.name
  ],
  (key, name) => key !== null && name === 'educator'
)

export const isPromoteOptionCheckedSelector = createSelector(
  [state => state.currentRegion.key],
  key => key === 'us_store'
)

export const currentRegionFlagPathSelector = createSelector(
  [state => state.currentRegion.parentConnectRegionId],
  id => `flag-${id.toLowerCase()}-rectangular.svg`
)

export const currentRegionDisplayNameSelector = createSelector(
  [state => state.currentRegion.displayName],
  name => name
)

export const currentRegionA11yTranslationSelector = createSelector(
  [currentRegionDisplayNameSelector],
  region => translate('regions.current_region', { current_region: region })
)

export const isCurrentRegionSelectorEnabledSelector = createSelector(
  [state => state.currentRegion.regionSelectorDisabled],
  isDisabled => !isDisabled
)

export const isUSCurrentRegionSelector = createSelector(
  [state => state.currentRegion.parentStoreKey],
  key => key === 'us_store'
)

export const salesRightsWarningToolTipTextSelector = createSelector(
  [state => state.currentRegion.parentConnectRegionId],
  id => translate(`tooltips.${id}_only_warning_tooltip`)
)

export const salesRightsWarningTextSelector = createSelector(
  [state => state.currentRegion.parentConnectRegionId],
  id => translate(`common.for_${id}_only`)
)

export const isUSRegionSelector = createSelector(
  [state => state.currentRegion.countryCodes],
  countryCodes => countryCodes[0] === US_REGION
)

export const isCurrentRegionUsingFlatTaxSelector = createSelector(
  [state => state.currentRegion.flatTaxMultiplier],
  flatTaxMultiplier => flatTaxMultiplier > 0
)
