import { VisuallyHidden } from "@reach/visually-hidden"
import PropTypes from 'prop-types'
import Svg from 'react-inlinesvg'
import { useSelector } from "react-redux"

import { QUANTITY_INPUT } from '@/constants/quantity_input'
import { isShowingQuantityInputOnPDPSelector } from
    '@/reducers/miniCart/selectors'

const QTY_OPTIONS = [
  'quantity-options',
  'd-flex',
  'justify-content-center',
  'align-items-center'
].join(' ')

const QTY_GROUP = [
  'quantity-options__group',
  'd-flex',
  'justify-content-center',
  'align-items-center'
].join(' ')

const QTY_DECREMENT = [
  'quantity-options__button',
  'quantity-options__button--decrement'
].join(' ')

const QTY_INCREMENT = [
  'quantity-options__button',
  'quantity-options__button--increment'
].join(' ')

const QtyInput = ({
    value,
    handleChange,
    increment,
    decrement,
    translations
  }) => {

  const currentRegion = useSelector(state => state.currentRegion)
  const incrementClass = currentRegion.abbreviation === 'SA'
    ? QTY_DECREMENT
    : QTY_INCREMENT
  const decrementClass = currentRegion.abbreviation === 'SA'
    ? QTY_INCREMENT
    : QTY_DECREMENT

  const isItemInCart = useSelector(isShowingQuantityInputOnPDPSelector)

  const preventScroll = e => e.target.blur()

  return (
    <If condition={isItemInCart}>
      <div className={QTY_OPTIONS}>
        <div className={QTY_GROUP}>
          <button
            aria-label={translations.decrease_button}
            className={decrementClass}
            onClick={decrement}
          >
            <Svg aria-hidden src={require('./bulk-quantity-minus.svg')} />
          </button>
          <VisuallyHidden>
            <label htmlFor="pdp-quantity">
              {translations.quantity_label}
            </label>
          </VisuallyHidden>
          <input
            className="quantity-options__input"
            id="pdp-quantity"
            max={QUANTITY_INPUT.max_value}
            maxLength={QUANTITY_INPUT.max_length}
            min={QUANTITY_INPUT.min_value}
            name="pdp-quantity"
            onChange={handleChange}
            onWheel={preventScroll}
            step="any"
            type="number"
            value={value}
          />
          <button
            aria-label={translations.increase_button}
            className={incrementClass}
            onClick={increment}
          >
            <Svg aria-hidden src={require('./bulk-quantity-plus.svg')} />
          </button>
        </div>
      </div>
    </If>
  )
}

QtyInput.propTypes = {
  decrement: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  increment: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    decrease_button: PropTypes.string,
    increase_button: PropTypes.string,
    quantity_label: PropTypes.string
  }).isRequired,
  value: PropTypes.number.isRequired
}

export default QtyInput
