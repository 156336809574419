import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'
import { mergeKeys } from '@/reducers/utils/helpers'

const mergeState = (state, { payload }) => ({
  ...state, ...payload[SLICES.lineItems]
})

const fetch = {
  fetchFailure: (state, { payload: error }) => mergeKeys(
    state,
    { status: { error, fetching: false } }
  ),
  fetchStart: (state, _action) => mergeKeys(
    state,
    { status: { error: null, fetching: true } }
  ),
  fetchSuccess: (state, { payload: { lineItems } }) => ({
    items: lineItems,
    status: { ...state.status, fetching: false }
  })
}

const afterCRUDAction = (state, { payload: { lineItems } }) => ({
  ...state,
  items: lineItems.items,
  status: {
    ...state.status,
    creating: false,
    destroying: false,
    error: null,
    updating: false
  }
})

const destroy = {
  destroyFailure: (state, { payload: error }) => mergeKeys(
    state,
    { status: { destroying: false, error } }
  ),
  destroyStart: (state, _action) => mergeKeys(
    state,
    { status: { destroying: true, error: null } }
  ),
  destroySuccess: afterCRUDAction
}

const update = {
  updateFailure: (state, { payload: error }) => mergeKeys(
    state,
    { status: { error, updating: false } }
  ),
  updateStart: (state, _action) => mergeKeys(
    state,
    { status: { error: null, updating: true } }
  ),
  updateSuccess: afterCRUDAction
}

const create = {
  createFailure: (state, { payload: error }) => mergeKeys(
    state,
    { status: { creating: false, error } }
  ),
  createStart: (state, _action) => mergeKeys(
    state,
    { status: { creating: true, error: null } }
  ),
  createSuccess: afterCRUDAction
}

export const reducers = {
  ...create,
  ...destroy,
  ...fetch,
  ...update
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: mergeState,
  [SHARED_ACTIONS.cart.applyPromoCodeSuccess]: mergeState,
  [SHARED_ACTIONS.cart.removePromoCodeSuccess]: mergeState
}
