import { SHARED_ACTIONS } from '@/reducers/utils/constants'

import { HANDLE_ERROR } from '../constants/actionTypes'

export const updateStoreAction = updatedState => ({
  payload: updatedState,
  type: SHARED_ACTIONS.updateStateFromBackend
})

export const handleError = response => ({
  payload: response,
  type: HANDLE_ERROR
})
