import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const classNames = [
  'vst-btn--large-full-width',
  'vst-btn--orange',
  'vst-btn--read-now'
]

const Trigger = ({ text }) => {
  const isBundle = useSelector(state => state.asset.isBundle)
  if (isBundle) classNames.push(' u-push-half--top')
  const styles = classNames.join(' ')

  return (
    <button
      className={styles}
      data-action='Launch'
      type='button'
    >
      {text}
    </button>
  )
}

export default Trigger

Trigger.propTypes = { text: PropTypes.string.isRequired }
