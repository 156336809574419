import { createSelector } from '@reduxjs/toolkit'
import map from 'lodash/map'

import { PROMO_CODE_ERRORS } from '@/constants/checkout'
import { isCurrentRegionUsingFlatTaxSelector } from '@/selectors'
import { isCheckoutPath } from '@/utils/path'
import { translate } from '@/utils/translations'

export const hasEmptyCartSelector = createSelector(
  [state => state.cart.itemsTotalCount],
  count => count === 0
)

export const cartHasErrorsSelector = createSelector(
  [state => state.cart.errors],
  errors => errors.length > 0
)

export const hasOffersToShowSelector = createSelector(
  [state => state.cart.offers.enabled],
  offers => offers.length > 0
)

export const taxAddressSelector = createSelector(
  [state => state.billingAddress],
  billingAddress => ({
    city: billingAddress.city || '',
    country: billingAddress.country || '',
    postalCode: billingAddress.zip || '',
    state: billingAddress.state || ''
  })
)

export const invalidQuantityMessageSelector = createSelector(
  [
    state => state.currentRegion.bulkEnabled
  ],
  _isBulkEnabled => {
    let enterValidQuantityMessage = translate('carts.enter_valid_quantity')

    return enterValidQuantityMessage
  }
)

export const canContinueCheckoutSelector = createSelector(
  [
    state => state.cart.canCheckout,
    state => state.cart.hasAcceptedTerms,
    state => state.cart.isInvalidPurchase,
    state => state.currentRegion.requiresTermsAcceptance
  ],
  (canCheckout, hasAcceptedTerms, isInvalidPurchase, requiresTerms) => {
    const canContinue = canCheckout && !isInvalidPurchase

    return requiresTerms ? canContinue && hasAcceptedTerms : canContinue
  }
)

export const displayTotalWithTaxesSelector = createSelector(
  [
    state => state.taxes.isCalculated,
    isCurrentRegionUsingFlatTaxSelector,
  ],
  (isCalculated, isUsingFlatTax) => (
    isCalculated && (isCheckoutPath || isUsingFlatTax)
  )
)

export const paymentRegisterTotalSelector = createSelector(
  [
    state => state.cart.formattedSubTotal,
    state => state.cart.total,
    displayTotalWithTaxesSelector
  ],
  (subTotal, total, displayTotalWithTaxes) => {
    if (displayTotalWithTaxes) {
      return total
    } else {
      return subTotal
    }
  }
)

export const hasBulkPurchaseOrderRequestSelector = createSelector(
  [
    state => state.currentRegion.bulkEnabled,
    state => state.currentRegion.purchaseOrderRequestEnabled
  ],
  (bulkEnabled, purchaseOrderRequestEnabled) => (
    bulkEnabled && purchaseOrderRequestEnabled
  )
)

export const isPurchaseOrderButtonDisabledSelector = createSelector(
  [state => state.cart.itemsTotalCount],
  itemCount => itemCount === 0
)

export const subTotalAsCentsSelector = createSelector(
  [state => state.cart.subTotal],
  subTotal => subTotal * 100
)

export const checkoutButtonTextSelector = createSelector(
  [state => state.mobileMenu.isLoggedIn],
  isLoggedIn => (
    isLoggedIn
    ? translate('carts.begin_checkout')
    : translate('carts.sign_in_and_checkout')
  )
)

export const isCartMinimumErrorSelector = createSelector(
  [
    state => state.cart.promoCode.errorType
  ],
  errorType => errorType === PROMO_CODE_ERRORS.cartMinimumError
)

const isCartIneligibleErrorSelector = createSelector(
  [
    state => state.cart.promoCode.errorType
  ],
  errorType => errorType === PROMO_CODE_ERRORS.cartIneligibleError
)

export const hasIneligibleItemsSelector = createSelector(
  [
    state => state.cart.promoCode.hasAppliedPromoCode,
    state => state.lineItems.items
  ],
  (
    hasAppliedPromoCode,
    items,
  ) => {
    const lineItemDiscountArray = map(items, 'isDiscountEligible')
    const isAnyIneligible = lineItemDiscountArray.some(bool => bool === false)

    return isAnyIneligible && hasAppliedPromoCode
  }
)

export const hasAppliedCodeOrErrorsSelector = createSelector(
  [
    state => state.cart.promoCode.hasAppliedPromoCode,
    state => state.cart.promoCode.errorType
  ],
  (hasAppliedPromoCode, hasPromoCodeErrors) => {
    return Boolean(hasAppliedPromoCode || hasPromoCodeErrors)
  }
)

export const isOtherPromoCodeErrorSelector = createSelector(
  [
    state => state.cart.promoCode.hasAppliedPromoCode,
    isCartMinimumErrorSelector,
    isCartIneligibleErrorSelector
  ],
  (hasAppliedPromoCode, isCartMinimumError, isCartIneligibleError) => {
    return (hasAppliedPromoCode && !isCartMinimumError) || isCartIneligibleError
  }
)

export const isBulkOrBulkIntegrationEnabledSelector = createSelector(
  [
    state => state.currentRegion.bulkEnabled,
    state => state.featureFlags.bulkIntegrationEnabled
  ],
  (isBulkEnabled, isBulkIntegrationEnabled) => {
    return isBulkEnabled || isBulkIntegrationEnabled
  }
)
