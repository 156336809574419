import { actions as flashMessagesActions } from '@/reducers/flashMessages'
import store from '@/store'
import { logError } from '@/utils/honeybadger'
import routes from '@/utils/router/routes.js.erb'

const prefixedRoute = ({ localeParam, pathPrefix, route }) => [
  '/',
  pathPrefix,
  localeParam ? `/${localeParam}` : '',
  route
].join('')

const addPrefixesToRoute = ({ localeParam, pathPrefix, route }) => {
  if (pathPrefix) {
    return prefixedRoute({ localeParam, pathPrefix, route })
  } else {
    return route
  }
}

export const routeFor = (name, routeParams, queryParams, state, options) => {
  state = state || store.getState()
  const localeParam = state.currentLocale.localeParam
  const currentRegionPathPrefix = state.currentRegion.pathPrefix
  const { ignorePrefix = false } = (options || {})
  const pathPrefix = ignorePrefix ? null : currentRegionPathPrefix

  try {
    let route

    // While you might think that this conditional is unnecessary b/c of how
    // javascript is supposed to treat undefined method parameters, it doesn't
    // work that way in this case.  The js-routes library seems to be doing
    // something fishy with the method params that it accepts.  If you delete
    // this conditional, you better make sure it still works with the js-routes
    // library.
    if (queryParams) {
      route = routes[`${name}Path`](routeParams, queryParams)
    } else {
      route = routes[`${name}Path`](routeParams)
    }
    return addPrefixesToRoute({ localeParam, pathPrefix, route })
  } catch (error) {
    logError(error, {
      name: 'RouteFor',
      params: { name, queryParams, routeParams }
    })

    store.dispatch(flashMessagesActions.handleError(
      error.message
    ))

    return false
  }
}

const parameterize = queryParams => Object.keys(queryParams)
  .map(key => `${key}=${queryParams[key]}`)
  .join('&')

export const newRouteFor = (url, queryParams) => {
  const localeParam = store.getState().currentLocale.localeParam
  const pathPrefix = store.getState().currentRegion.pathPrefix

  if (queryParams) {
    url = `${url}?${parameterize(queryParams)}`
  }

  return addPrefixesToRoute({ localeParam, pathPrefix, route: url })
}
