import { formatMoney } from 'accounting-js'

/* eslint-disable complexity */
class LocalePrice {
  constructor({
    currency,
    flatTaxMultiplier,
    isZeroDecimalCurrency,
    options,
    price,
    taxMultiplier,
    translations
  }) {
    const { hideCurrencyLabel } = (options || {})
    const { useNZTax } = (options || {})
    const { common_no_price_product } = translations
    let { label, symbol } = currency

    if (!label || hideCurrencyLabel) {
      label = ''
    } else {
      label = ' ' + label
    }

    if(useNZTax) {
      const multiplier = 1 + flatTaxMultiplier

      taxMultiplier = multiplier
    }

    taxMultiplier = parseFloat(taxMultiplier)

    const totalPrice = price * taxMultiplier
    const currencyPrecision = isZeroDecimalCurrency ? 0 : 2

    let formattedPrice

    if (totalPrice > 0) {
      formattedPrice = formatMoney(
        totalPrice, { precision: currencyPrecision, symbol: symbol }
      ) + label

    } else {
      formattedPrice = common_no_price_product
    }

    this.formattedAmount = formattedPrice
  }
}

export default LocalePrice
