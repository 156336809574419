import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.accordion]
})

const updateAccordionEventKey = (state, { payload }) => ({
  ...state,
  eventKey: payload
})

const updateIsShowingReadMore = (state, { payload }) => ({
  ...state,
  isShowingReadMore: payload
})
const updateDefaultEventKey = (state, { payload }) => ({
  ...state,
  payload
})

export const reducers = {
  updateAccordionEventKey,
  updateDefaultEventKey,
  updateIsShowingReadMore
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
