import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  institution: { connectId: '', name: '', options: [] },
  institutions: [],
  isShowingResults: false,
}

const institutions = createSlice({
  extraReducers,
  initialState,
  name: SLICES.institutions,
  reducers
})

export const { actions } = institutions
export default institutions
