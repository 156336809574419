import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.institutions]
})

const fetch = {
  fetchFailure: state => state,
  fetchStart: (state, { payload: query }) => ({ ...state, query }),
  fetchSuccess: (state, { payload: institutions }) =>({
    ...state,
    institutions: institutions.results,
    isShowingResults: true,
  })
}

const updateInstitution = (state, { payload: institution }) => ({
  ...state,
  institution: {
    ...institution,
    connectId: institution.options[0].connectId
  },
  isShowingResults: false
})

const updateInstitutionId = (state, { payload: connectId }) => ({
  ...state,
  institution: {
    ...state.institution,
    connectId
  }
})

const clearSelectedInstitution = state => ({
  ...state,
  institution: { connectId: '', id: '', name: '', options: [] },
  institutions: [],
  isShowingResults: false,
})

export const reducers = {
  ...fetch,
  clearSelectedInstitution,
  updateInstitution,
  updateInstitutionId
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
