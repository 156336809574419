import PropTypes from 'prop-types'

import { translate } from '@/utils/translations'

const Label = ({
  isSubscription,
  variant: { durationExpirationText, id, studentPrice }
}) => {

  return (
    <label
      htmlFor={id}
      id={`variant-${id}`}
    >
      <Choose>
        <When condition={isSubscription}>
          {translate('purchase.purchase_item')}
        </When>
        <Otherwise>
          {durationExpirationText}
          <div className='price'>{studentPrice}</div>
        </Otherwise>
      </Choose>
    </label>
  )
}

export default Label

Label.propTypes = {
  isSubscription: PropTypes.bool.isRequired,
  variant: PropTypes.shape({
    durationExpirationText: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    studentPrice: PropTypes.string.isRequired
  })
}
