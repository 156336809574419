import { useSelector } from 'react-redux'

import { disabledCartActionButtonTextSelector } from '@/selectors'

const DisabledActionButton = () => {
  const disabledCartActionText = useSelector(
    disabledCartActionButtonTextSelector
  )
  const className = [
    'vst-btn--large-full-width',
    'vst-btn--orange',
    'add_to_cart'
  ].join(' ')

  return (
    <button
      className={className}
      disabled
    >
      {disabledCartActionText}
    </button>
  )
}

export default DisabledActionButton
