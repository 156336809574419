import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'
import { translate } from '@/utils/translations'

const mergeData = (state, { payload }) => ({
  ...state, ...payload[SLICES.billingAddress]
})

const updateFields = (state, {
  payload: { firstName, lastName, address }
}) => ({
  ...state,
  city: address.city,
  country: address.country,
  firstName,
  lastName,
  secondaryAddress: address.line2,
  state: address.state,
  streetAddress: address.line1,
  zip: address.postal_code
})

const update = {
  updateFailure: (state, { payload }) => {
    const updatedState = {
      ...state,
      isUpdating: false
    }
    const { error, status } = payload

    const defaultError = error.errors || error

    switch (status) {
      case 429:
        return {
          ...updatedState,
          errors: [translate('errors.recaptcha_needed_due_to_throttling')],
          isForcingRecaptcha: true
        }
      case 500:
        return {
          ...updatedState,
          errors: [translate('errors.unexpected_error')]
        }
      default:
        return {
          ...updatedState,
          errors: defaultError
        }
    }
  },
  updateStart: (state, _action) => ({
    ...state,
    errors: {},
    isForcingRecaptcha: false,
    isUpdating: true
  }),
  updateStartV2: (state, _action) => ({
    ...state,
    errors: {},
    isForcingRecaptcha: false,
    isUpdating: true
  }),
  updateSuccess: (state, _action) => ({
    ...state,
    isUpdating: false
  }),
  updateSuccessV2: (state, _action) => ({
    ...state,
    isUpdating: false
  })
}

const clearBillingAddressState = (state, _action) => ({
  ...state,
  state: null
})

export const reducers = {
  ...update,
  updateFields
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: mergeData,
  [SHARED_ACTIONS.checkout.applePayInstitutionRequired]: mergeData,
  [SHARED_ACTIONS.checkout.paypalAuthorizeSuccess]: mergeData,
  [SHARED_ACTIONS.checkout.fetchCountrySubdivisionsSuccess]:
    clearBillingAddressState,
}
