import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const mergeState = (state, { payload }) => ({
  ...state, ...payload[SLICES.taxes]
})

const replaceState = (_state, { payload }) => payload[SLICES.taxes]

const calculateTaxes = {
  calculateTaxesFailure: state => ({
      ...state,
      isCalculating: false
    }),
  calculateTaxesStart: state => ({
    ...state,
    isCalculating: true
  }),
  calculateTaxesSuccess: state => ({
    ...state,
    isCalculating: false
  })
}

const paypalAuthorizeSuccess = (state, { payload: { errors, taxes } }) => {
  const hasError = errors.length > 0

  return hasError ? state : taxes
}

export const reducers = { ...calculateTaxes }

export const extraReducers = {
  [SHARED_ACTIONS.checkout.paypalAuthorizeSuccess]: paypalAuthorizeSuccess,
  [SHARED_ACTIONS.checkout.updatePendingPurchaseInstitutionSuccess]: mergeState,
  [SHARED_ACTIONS.cart.applyPromoCodeSuccess]: mergeState,
  [SHARED_ACTIONS.cart.removePromoCodeSuccess]: mergeState,
  [SHARED_ACTIONS.lineItems.createSuccess]: replaceState,
  [SHARED_ACTIONS.lineItems.destroySuccess]: replaceState,
  [SHARED_ACTIONS.lineItems.updateSuccess]: replaceState,
  [SHARED_ACTIONS.updateStateFromBackend]: mergeState,
  [SHARED_ACTIONS.billingAddress.updateSuccess]: mergeState,
  [SHARED_ACTIONS.billingAddress.updateSuccessV2]: mergeState
}
