import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ReduxProvider from '@/components/ReduxProvider'
import { priceSelector, quantitySelector } from '@/selectors'

const PayPalBNPL = ({ isPayPalBNPLEnabled, total }) => {
  const [price, setPrice] = useState(total.amount)
  const quantity = useSelector(quantitySelector)
  const studentPrice = useSelector(priceSelector({ quantity }))

  useEffect(() => {
    setPrice(studentPrice)
  }, [studentPrice])

  return (
    <If condition={isPayPalBNPLEnabled && price}>
      <div
        className='mt-4'
        data-pp-amount={price}
        data-pp-message
        data-pp-style-layout='text'
        data-pp-style-logo-type='inline'
        data-pp-style-text-color='black'
        data-pp-style-text-size='16'
      />
    </If>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = ({ isPayPalBNPLEnabled, total }) => (
  <ReduxProvider>
    <PayPalBNPL {...{ isPayPalBNPLEnabled, total }} />
  </ReduxProvider>

)

export default Component

PayPalBNPL.propTypes = {
  isPayPalBNPLEnabled: PropTypes.bool,
  total: PropTypes.object
}

Component.propTypes = {
  isPayPalBNPLEnabled: PropTypes.bool,
  total: PropTypes.object
}
