import { localePrice } from '@/utils/priceLocalization'

const StudentDiscountPrice = function ({
  discountAmount,
  studentPrice,
  useNZTax
}) {
  let price = 0
  let parsedDiscountAmount = parseFloat(discountAmount)
  let parsedStudentPrice = parseFloat(studentPrice)

  if (parsedDiscountAmount < parsedStudentPrice) {
    price = parsedStudentPrice - parsedDiscountAmount
  }

  this.isShowing = Boolean(studentPrice)
  this.formattedAmount = localePrice(
    price, { hideCurrencyLabel: true, useNZTax }
  )
}

export default StudentDiscountPrice
