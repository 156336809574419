import { VisuallyHidden } from '@reach/visually-hidden'

import { translate } from '@/utils/translations'

const Footnotes = () => (
  <div className='foot-notes'>
    <VisuallyHidden>
      <h2>{translate('common.footnote_header')}</h2>
    </VisuallyHidden>
    <ul>
      <li id='coursekey' tabIndex='-1'>
        {translate('order_history.form.access_code.foot_note')}
        <a
          aria-label={translate('common.back_to_content')}
          href='#footnotes-ref'
        >
          {'↩'}
        </a>
      </li>
    </ul>
  </div>
)

export default Footnotes
