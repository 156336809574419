require('./index.scss')

import { useDispatch } from 'react-redux'

import ReduxProvider from '../ReduxProvider'
import AssetDetails from './AssetDetails'
import { SearchResultPropTypes } from './SearchResultProps'

const GTM_TYPE = 'gtm/productClickSRP'
const SRP_RECOMMENDED = 'Search Results'
const SRP_RECOMMENDED_ID = 'search_results'
const list = {
  listId: SRP_RECOMMENDED_ID,
  listName: SRP_RECOMMENDED
}

const SearchResult = ({
  assetProps,
  coverImageUrl,
  formattedPrice,
  hasPriceRange,
  minPrice,
  position,
  productId,
  productUrl,
  taxInfoMessage,
  translations
}) => {
  const { canonicalIsbn, imprintName, kind, title } = assetProps

  const dispatch = useDispatch()

  const onClickHandler = () => {
    dispatch({
      payload: {
        canonicalIsbn, imprintName, kind, list, minPrice, position, title
      },
      type: GTM_TYPE
    })
  }

  return (
    <AssetDetails {...{
      assetProps,
      coverImageUrl,
      formattedPrice,
      hasPriceRange,
      onClickHandler,
      productId,
      productUrl,
      taxInfoMessage,
      translations
    }}
    />
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = ({
  assetProps,
  badges,
  coverImageUrl,
  formattedPrice,
  hasPriceRange,
  minPrice,
  position,
  productId,
  productUrl,
  taxInfoMessage,
  translations
}) => (
  <ReduxProvider>
    <SearchResult {...{
      assetProps,
      badges,
      coverImageUrl,
      formattedPrice,
      hasPriceRange,
      minPrice,
      position,
      productId,
      productUrl,
      taxInfoMessage,
      translations
    }}
    />
  </ReduxProvider>
)

export default Component

SearchResult.propTypes = SearchResultPropTypes
Component.propTypes = SearchResultPropTypes
