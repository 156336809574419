import ExternalLinkIcon from '@/components/ExternalLinkIcon'
import settings from '@/utils/settings'
import { translate } from '@/utils/translations'

const RefundInfo = () => (
  <div className='d-flex flex-row align-items-start mb-2'>
    <img
      alt=''
      className='u-push-half--right mt-2'
      src={require('/app/assets/images/info-tooltip-icon.svg')}
    />
    <p className='u-weight--bold'>
      {`${translate('order_history.form.access_code.info')} `}
      <a
        className='type--ocean6 u-text--underline'
        href={settings.vst_support_refund_policy_link}
        rel='noreferrer noopener'
        target='_blank'
      >
        {translate('order_history.link.review_return_policy')}
        <ExternalLinkIcon theme='light' />
      </a>
    </p>
  </div>
)

export default RefundInfo
