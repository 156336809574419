import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.miniCart]
})

const setIsShowing = (state, { payload }) => ({ ...state, isShowing: payload })

export const reducers = { setIsShowing }

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
