export function highlight (event) {
  event.preventDefault()
  event.target.focus()
  event.target.select()
}

export function copyToClipboard ({ id }) {
  document.getElementById(id).select()
  document.execCommand('copy')
}
