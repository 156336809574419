import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {}

const activeSamplingRegions = createSlice({
  extraReducers,
  initialState,
  name: SLICES.activeSamplingRegions,
  reducers
})

export const { actions } = activeSamplingRegions
export default activeSamplingRegions
