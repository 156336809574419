import axios from 'axios'

import {
  prepareData,
  prepareFileFormData,
  prepareFormData,
  processAxiosResponse,
} from './data'
import { handle as handleError } from './errors'

axios.defaults.withCredentials = true

const errorOptions = ({ data, name, url }) => {
  const fingerprint = `${name}: ${url.replace(/\d+/g, 'XX')}`

  return {
    fingerprint: fingerprint,
    name: fingerprint,
    params: data
  }
}

const axiosResponse = ({ method, url, data }) => processAxiosResponse(
  axios({ data: prepareData(data), method, url })
).catch(error => handleError(error, errorOptions({
  data,
  name: `HttpAxiosResponse-${method}`,
  url
})))

const axiosGet = ({ url, data }) => processAxiosResponse(
  axios.get(url, { params: prepareData(data) })
).catch(error => handleError(error, errorOptions({
  data,
  name: 'HttpAxiosGet',
  url
})))

const axiosDelete = ({ url, data }) => processAxiosResponse(
  axios.delete(url, { params: prepareData(data) })
).catch(error => handleError(error, errorOptions({
  data,
  name: 'HttpAxiosDelete',
  url
})))

const axiosPost = ({ url, data }) => processAxiosResponse(
  axios.post(url, prepareData(data))
).catch(error => handleError(error, errorOptions({
  data,
  name: 'HttpAxiosPost',
  url
})))

const axiosForm = ({ method, url, options }) => {
  const formData = prepareFormData(options)
  const axiosFormOptions = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  }

  return processAxiosResponse(
    axios[method](url, formData, axiosFormOptions)
  ).catch(error => handleError(error, errorOptions({
    data: options,
    name: 'HttpAxiosForm',
    url
  })))
}

const axiosPostFile = ({ url, file, options }) => {
  const formData = prepareFileFormData({ file, options })
  const axiosFormOptions = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }

  return processAxiosResponse(
    axios.post(url, formData, axiosFormOptions)
  ).catch(error => handleError(error, errorOptions({
    data: { file, ...options },
    name: 'HttpAxiosPostFile',
    url
  })))
}

export default {
  delete: (url, data) => axiosDelete({ data, url }),
  get: (url, data) => axiosGet({ data, url }),
  patch: (url, data) => axiosResponse({ data, method: 'patch', url }),
  post: (url, data) => axiosPost({ data, url }),
  postFile: (url, file, options) => axiosPostFile({ file, options, url }),
  postForm: (url, options) => axiosForm({ method: 'post', options, url }),
  put: (url, data) => axiosResponse({ data, method: 'put', url }),
  putForm: (url, options) => axiosForm({ method: 'put', options, url }),
}
