import camelize from 'camelize'

import { SHARED_ACTIONS } from '@/reducers/utils/constants'

export const updateStateFromBackendAction = updatedState => ({
  payload: updatedState,
  type: SHARED_ACTIONS.updateStateFromBackend
})

export const eventDispatcher = ({ events, state, store }) => {
  if (state) {
    store.dispatch(updateStateFromBackendAction(camelize(state)))
  }
  (events || []).forEach(event => store.dispatch(event))
}
