import { useSelector } from 'react-redux'

import AddToCart from '@/components/PDP/PricingPanel/AddToCartButton/AddToCart'
import { addToCartButtonTextSelector } from '@/selectors'

const AddToCartButton = () => {
  const addToCartButtonText = useSelector(addToCartButtonTextSelector)

  return (
    <AddToCart>
      {addToCartButtonText}
    </AddToCart>
  )
}

export default AddToCartButton
