import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  city: null,
  country: null,
  errors: {},
  firstName: null,
  isForcingRecaptcha: false,
  isUpdating: false,
  lastName: null,
  secondaryAddress: null,
  state: null,
  streetAddress: null,
  zip: null
}

const billingAddress = createSlice({
  extraReducers,
  initialState,
  name: SLICES.billingAddress,
  reducers
})

export const { actions } = billingAddress
export default billingAddress
