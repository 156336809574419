import { useSelector } from 'react-redux'

import { translate } from '@/utils/translations'

const TaxLabel = () => {
  const taxLabel = useSelector(state => state.expressCheckout.taxLabel)

  return (
    <li>
      <p>
        {taxLabel}
      </p>
      <p>{translate('carts.calculated_in_checkout')}</p>
    </li>
  )
}

export default TaxLabel
