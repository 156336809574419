import { useState } from 'react'
import { useSelector } from 'react-redux'

import OnlineResourceModal from '@/components/OnlineResourceModal/'
import { onlineResourceButtonTextSelector } from '@/selectors'

import ReduxProvider from '../ReduxProvider'
import Trigger from './Trigger'

const CoursewareModal = () => {
  const [isShowing, setIsShowing] = useState(false)
  const text = useSelector(onlineResourceButtonTextSelector)
  const title = useSelector(state => state.asset.title)
  const imageUrl = useSelector(state => state.asset.productImageUrl)
  const kind = useSelector(state => state.asset.kind)
  const isbn = useSelector(state => state.asset.vbid)

  return (
    <If condition={isbn}>
      <OnlineResourceModal
        imageUrl={imageUrl}
        isShowing={isShowing}
        isbn={isbn}
        kind={kind}
        onChangeShow={setIsShowing}
        title={title}
        trigger={<Trigger onClick={() => setIsShowing(true)} text={text} />}
      />
    </If>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = () => {
  return (
    <ReduxProvider>
      <CoursewareModal />
    </ReduxProvider>
  )
}
export default Component
