require('./index.scss')

import PropTypes from 'prop-types'

import BulkStorePdpLink from './BulkStorePdpLink'
import Variant from './Variant'

const className = [
  'card',
  'pdp-redesign-student-pricing-panel',
  'qa-student-pricing-panel'
].join(' ')

const StudentPricingPanel = ({
  bulkStoreUrl,
  headerText,
  isShowingBulkLink,
  variants,
  variantOrder
}) => (
  <div className={className} >
    <p className='product-overview__student_pricing__header'>
      {headerText}
    </p>
    {variantOrder.map(variantId => (
      <Variant
        key={variantId}
        variant={variants[variantId]}
      />
    ))}
    <If condition={isShowingBulkLink}>
      <BulkStorePdpLink bulkStoreUrl={bulkStoreUrl} />
    </If>
  </div>
)

export default StudentPricingPanel

StudentPricingPanel.propTypes = {
  bulkStoreUrl: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  isShowingBulkLink: PropTypes.bool.isRequired,
  variantOrder: PropTypes.array.isRequired,
  variants: PropTypes.object.isRequired
}
