import { translate } from '@/utils/translations'

export const PROMO_CODE_ERRORS = {
  cartIneligibleError: 'cart_ineligible_error',
  cartMinimumError: 'cart_minimum_error'
}

export const PAYMENT_TYPES = {
  applepay: 'apple_pay',
  paypal: 'paypal',
  stripe: 'stripe',
  watchman: 'watchman'
}

export const PAYMENT_REQUEST_LABEL = 'VitalSource'

export const CREDIT_CARD_PAYMENT_TYPES = [
  PAYMENT_TYPES.stripe
]

export const FORM_STEPS = {
  billingAddress: 'billingAddress',
  choosePayment: 'choosePayment',
  creditCardPayment: 'creditCardPayment',
  editBillingAddressStep: 'editBillingAddressStep',
  financialAidConfirmPurchase: 'financialAidConfirmPurchase',
  institutionPicker: 'institutionPicker',
  institutionPickerEdit: 'institutionPickerEdit',
  paypalConfirmPurchase: 'paypalConfirmPurchase',
  savedPaymentPreview: 'savedPaymentPreview'
}

export const STRIPE_STATES = {
  authorizedPayment: 'authorizedPayment',
  createdPaymentMethod: 'createdPaymentMethod',
  handledCardAction: 'handledCardAction',
  handlingCardAction: 'handlingCardAction',
  initial: 'initial',
  processing: 'processing'
}

export const GENERIC_ERROR = {
  message: translate('errors.unexpected_error')
}
