import { createSelector } from '@reduxjs/toolkit'

import { localePrice } from '@/utils/priceLocalization'
import { translate } from '@/utils/translations'

export const expressCheckoutParamsSelector = createSelector(
  [
    state => state.asset.id,
    state => state.asset.selectedVariant.duration
  ],
  (assetId, duration) => ({ assetId, duration })
)

export const hasExpressCheckoutSavingsSelector = createSelector(
  [state => state.asset.selectedVariant.savings],
  savings => savings > 0
)

export const expressCheckoutSavingsSelector = createSelector(
  [ state => state.asset.selectedVariant.savings],
  savings => localePrice(savings, { hideCurrencyLabel: true })
)

export const expressCheckoutSubtotalSelector = createSelector(
  [ state => state.asset.selectedVariant.studentPrice],
  subtotal => localePrice(subtotal, { hideCurrencyLabel: true })
)

export const expressCheckoutHasSavedPaymentSelector = createSelector(
  [ state => state.expressCheckout.user.hasSavedPayment,
    state => state.user.isVisitor
  ],
  (hasSavedPayment, isVisitor) => hasSavedPayment && !isVisitor
)

export const expressCheckoutDialogTitleSelector = createSelector(
  [expressCheckoutHasSavedPaymentSelector],
  hasSavedPayment => hasSavedPayment
    ? translate('carts.express_checkout.review_and_pay')
    : translate('common.buy_now')
)

export const expressCheckoutSavedPaymentExpirationTextSelector = createSelector(
  [state => state.expressCheckout.savedPayment.expiration],
  expiration => `${translate('carts.expiration_date')} ${expiration}`
)

export const expressCheckoutSavedPaymentCardInfoSelector = createSelector(
  [
    state => state.expressCheckout.savedPayment.brand,
    state => state.expressCheckout.savedPayment.lastFour
  ],
  (brand, lastFour) => (
    `${translate('checkout.payment_type.stripe')}: ${brand} ...${lastFour}`
  )
)
