import debounce from 'debounce'
import PropTypes from "prop-types"
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import ReduxProvider from '@/components/ReduxProvider'
import { QUANTITY_INPUT } from '@/constants/quantity_input'
import { actions as assetActions } from '@/reducers/asset'
import { handleQuantityChange } from '@/utils/quantityValidator'

import QtyInput from './QtyInput'

const QuantityWithOptions = ({ translations }) => {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState(1)

  const handleDebouncedChange = debounce(value => {
    dispatch(assetActions.updateQuantity(value))
  }, 300)

  useEffect(() => {
    handleDebouncedChange(inputValue)
  }, [inputValue])

  const handleChange = e => {
    let value = Number(e.target.value)

    handleQuantityChange(value, setInputValue)
  }

  const decrement = () => {
    setInputValue(prevValue => Math.max(prevValue - 1, 1))
  }

  const increment = () => {
    setInputValue(prevValue => Math.min(
      prevValue + 1, QUANTITY_INPUT.max_value
    ))
  }

  return (
    <QtyInput
      decrement={decrement}
      handleChange={handleChange}
      increment={increment}
      translations={translations}
      value={inputValue}
    />
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = ({ translations }) => (
  <ReduxProvider>
    <QuantityWithOptions {...{ translations }} />
  </ReduxProvider>
)

QuantityWithOptions.propTypes = {
  translations: PropTypes.shape({
    decrease_button: PropTypes.string,
    increase_button: PropTypes.string,
    quantity_label: PropTypes.string
  }).isRequired
}

Component.propTypes = {
  translations: PropTypes.shape({
    decrease_button: PropTypes.string,
    increase_button: PropTypes.string,
    quantity_label: PropTypes.string
  }).isRequired
}

export default Component
