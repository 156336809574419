import { createSlice } from '@reduxjs/toolkit'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  isVisitor: true,
  library: {
    isAssetInLibrary: null,
    licenses: [{ expiration: '', vbid: '' }]
  },
  searchableRole: {
    name: ''
  }
}

const user = createSlice({
  extraReducers,
  initialState,
  name: 'user',
  reducers
})

export const { actions } = user
export default user
