import { useDispatch, useSelector } from 'react-redux'

import Spinner from '@/components/Spinner'
import { update } from '@/reducers/lineItems/asyncActions'
import {
  assetLineItemSelector,
  isVariantUpdatedSelector,
  quantitySelector
} from '@/selectors'
import { routeFor } from '@/utils/router'
import { translate } from '@/utils/translations'

import { cartClassName } from './helpers'

const spinnerBtnClass = [
  'sk-spinner',
  'sk-spinner-three-bounce-white'
].join(' ')

const ProceedToCheckoutButton = () => {
  const isUpdatingItem = useSelector(state => state.lineItems.status.updating)
  const isVariantUpdated = useSelector(isVariantUpdatedSelector)
  const quantity = useSelector(quantitySelector)
  const variantId = useSelector(state => state.asset.selectedVariant.id)
  const lineItemId = useSelector(assetLineItemSelector).id

  const dispatch = useDispatch()
  const proceedToCart = event => {
    event.preventDefault()

    if (isVariantUpdated) {
      dispatch(
        update({ id: lineItemId, quantity, variantId }, routeFor('cart'))
      )
    } else {
      window.location.assign(routeFor('cart'))
    }
  }

  return (
    <button
      className={cartClassName}
      onClick={proceedToCart}
    >
      <Choose>
        <When condition={isUpdatingItem}>
          <Spinner styles={spinnerBtnClass} />
        </When>
        <Otherwise>
          {translate('carts.proceed_to_checkout')}
        </Otherwise>
      </Choose>
    </button>
  )
}

export default ProceedToCheckoutButton
