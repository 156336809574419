import PropTypes from 'prop-types'

const CartItemExpiration = ({ cartItemVariant, isDisplayDivider }) => {
  const { isShowingExpiration, isPerpetual } = cartItemVariant

  return (
    <If condition={isShowingExpiration && !isPerpetual}>
      <If condition={isDisplayDivider}>
        <span aria-hidden='true' className='d-none d-md-block mx-md-2'>
          {'|'}
        </span>
      </If>
      <span className='duration-details-text'>
        {cartItemVariant.expiration}
      </span>
    </If>
  )
}

export default CartItemExpiration

CartItemExpiration.propTypes = {
  cartItemVariant: PropTypes.shape({
    duration: PropTypes.string.isRequired,
    expiration: PropTypes.string.isRequired,
    isPerpetual: PropTypes.bool.isRequired,
    isShowingExpiration: PropTypes.bool.isRequired
  }),
  isDisplayDivider: PropTypes.bool
}
