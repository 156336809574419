import { translate } from '@/utils/translations'

const GrandTotal = () => (
  <>
    <hr />
    <li>
      <p className='u-weight--bold order_summary_subtotal'>
        {`${translate('common.order_total_label')}:`}
      </p>
      <p className='u-weight--bold order_summary_subtotal'>
        {'$22.06'}
      </p>
    </li>
  </>
)

export default GrandTotal
