import Honeybadger from '@honeybadger-io/js'

import settings from '@/utils/settings'

import {
  DEPLOYED_ENVIRONMENTS,
  ERROR_PROXY_INSTANCE,
  FILTERED_KEYS,
  REDACTED_VALUE
} from './constants'

const RAILS_ENV = process.env.RAILS_ENV

const isDeployedEnvironment = DEPLOYED_ENVIRONMENTS.includes(RAILS_ENV)

const isTestEnvironment = process.env.NODE_ENV === 'test'

const config = {
  apiKey: settings.honeybadger_js_api_key,
  enableUnhandledRejection: false,
  environment: window.environment,
  revision: settings.git_commit_sha
}

let honeybadger

if(isDeployedEnvironment) {
  honeybadger = Honeybadger.configure(config)

  const filterValues = object => {
    Object.keys(object || {}).forEach(function(key) {
      if (FILTERED_KEYS.includes(key)) {
        object[key] = REDACTED_VALUE
      }
    })
  }

  honeybadger.beforeNotify(({ params = {} }) => {
    filterValues(params)
    filterValues(params.user_form)
  })

  honeybadger.setContext({ user_id: window.userId })
}

window.addEventListener('load', () => {
  const host = window.location.host

  if (settings.allowed_hosts && !settings.allowed_hosts.some(
    allowedHost => host.indexOf(allowedHost) !== -1
  )) {
    logError(ERROR_PROXY_INSTANCE, {
      name: ERROR_PROXY_INSTANCE,
      params: { host }
    })
  }
})

export function logError(error, options) {
  if(isDeployedEnvironment) {
    honeybadger.notify(error, options)
  } else if(!isTestEnvironment) {
    console.error(error)
  }
}

export default honeybadger
