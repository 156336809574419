import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const mergeState = (state, { payload }) => ({
  ...state, ...payload[SLICES.cart]
})

const replaceState = (_state, { payload }) => payload[SLICES.cart]

const onTermsUnchecked = state => ({
  ...state,
  hasAcceptedTerms: false
})

const onToggleOfferTerms = state => ({
  ...state,
  hasAcceptedOfferTerms: !state.hasAcceptedOfferTerms
})

const updateTermsAcceptanceCompleted = state => ({
  ...state,
  hasAcceptedTerms: true,
  isUpdating: false
})

const updateTermsAcceptance = {
  updateTermsAcceptanceFailure: state => ({
    ...state,
    hasAcceptedTerms: false,
    isUpdating: false
  }),
  updateTermsAcceptanceStart: state => ({
    ...state,
    isUpdating: true
  }),
  updateTermsAcceptanceSuccess: updateTermsAcceptanceCompleted,
}

const applyPromoCode = {
  applyPromoCodeFailure: (state, { payload }) => ({
    ...state,
    promoCode: {
      error: payload.error,
      errorType: payload.errorType,
      isUpdating: false
    }
  }),
  applyPromoCodeStart: state => ({
    ...state,
    promoCode: {
      isUpdating: true
    }
  }),
  applyPromoCodeSuccess: (state, { payload }) => ({
    ...state,
    ...payload.cart
  })
}

const removePromoCode = {
  removePromoCodeFailure: (state, { payload }) => ({
    ...state,
    promoCode: {
      ...state.promoCode,
      error: payload.error,
      isUpdating: false
    }
  }),
  removePromoCodeStart: state => ({
    ...state,
    promoCode: {
      ...state.promoCode,
      isUpdating: true
    }
  }),
  removePromoCodeSuccess: (state, { payload }) => ({
    ...state,
    ...payload.cart
  })
}

export const reducers = {
  ...applyPromoCode,
  ...removePromoCode,
  ...updateTermsAcceptance,
  onTermsUnchecked,
  onToggleOfferTerms
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: mergeState,
  [SHARED_ACTIONS.cart.applyPromoCodeSuccess]: replaceState,
  [SHARED_ACTIONS.cart.removePromoCodeSuccess]: replaceState,
  [SHARED_ACTIONS.checkout.paypalAuthorizeSuccess]: mergeState,
  [SHARED_ACTIONS.checkout.updatePendingPurchaseInstitutionSuccess]: mergeState,
  [SHARED_ACTIONS.lineItems.destroySuccess]: replaceState,
  [SHARED_ACTIONS.lineItems.updateSuccess]: replaceState,
  [SHARED_ACTIONS.lineItems.createSuccess]: replaceState,
  [SHARED_ACTIONS.billingAddress.updateSuccess]: mergeState,
  [SHARED_ACTIONS.billingAddress.updateSuccessV2]: mergeState,
}
