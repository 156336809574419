import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

export const initialState = {
  backOfficePermissions: [],
  hasBackOfficeAccess: null,
  hasDashboard: null,
  isInstructor: null
}

const dashboardMenu = createSlice({
  extraReducers,
  initialState,
  name: SLICES.dashboardMenu,
  reducers
})

export const { actions } = dashboardMenu
export default dashboardMenu
