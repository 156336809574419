import { expirationText, formatLicenseExpiration } from '@/utils/durationUtils'

class LicenseExpiration {
  constructor({
    duration, durationInDays, daysLeftInLicense, isOnlineResource
  })
  {
    let expiresText = expirationText(daysLeftInLicense)
    this.text = `${expiresText} ${formatLicenseExpiration(
      durationInDays, daysLeftInLicense
    )}`
    this.isPerpetual = duration === 'perpetual' && !isOnlineResource
  }
}

export default LicenseExpiration
