import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.onlineResourceModal]
})

const fetch = {
  fetchFailure: (state, { payload, vbid }) => {
    const { error } = payload
  
    return ({
      ...state,
      [vbid]: {
        accessCode: null,
        courseKey: null,
        skipReveal: false,
        status: { error, fetching: false }
      }
    })
  },
  fetchStart: (state, { payload: { vbid } }) => ({
    ...state,
    [vbid]: {
      accessCode: null,
      courseKey: null,
      skipReveal: false,
      status: { error: null, fetching: true }
    }
  }),
  fetchSuccess: (state, { payload: { response, vbid } }) => {
    const { courseKey, accessCode, loginUrl, skipReveal, } = response

    return ({
      ...state,
      [vbid]: {
        accessCode,
        courseKey,
        loginUrl,
        skipReveal,
        status: { error: null, fetching: false }
      }
    })
  }
}

export const reducers = { ...fetch }

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
