import { translate } from '@/utils/translations'

const Trigger = () => (
  <button
    className='vst-btn--large vst-btn--orange-outline u-push--top'
    type="button"
  >
    {translate('common.buy_now')}
  </button>
)

export default Trigger
