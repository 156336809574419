import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'

import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

export const newRecordTemplate = {
  email: '',
  firstName: '',
  isEditing: true,
  lastName: ''
}

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.publisherUsers]
})

const removeUser = {
  removeUserFailure: state => ({ ...state, isPending: false }),
  removeUserStart: state => ({ ...state, isPending: true }),
  removeUserSuccess: (state, { payload: user }) =>({
    ...state,
    isPending: false,
    publisherUsers: differenceWith(state.publisherUsers, [user], isEqual)
  })
}

const grantAccess = {
  grantAccessFailure: state => ({ ...state, isPending: false }),
  grantAccessStart: state => ({ ...state, isPending: true }),
  grantAccessSuccess: (state, { payload: user }) => ({
    ...state,
    isPending: false,
    newRecord: null,
    publisherUsers: state.publisherUsers.concat(user)
  })
}

const updatedRepsFromImport = ({ newReps, previousReps }) => {
  let publisherUsers = previousReps
  const publisherUsersEmails = publisherUsers.map(
    user => user.email.toLowerCase()
  )

  newReps
    .filter(rep => rep.state === 'Succeeded')
    .forEach(({ email, firstName, lastName }) => {
      if (!publisherUsersEmails.includes(email.toLowerCase())) {
        publisherUsers = publisherUsers.concat({ email, firstName, lastName })
      }
    })

  return publisherUsers
}

const getRows = {
  getRowsFailure: state => ({ ...state, isPending: false }),
  getRowsStart: state => ({ ...state, isPending: true }),
  getRowsSuccess: (state, { payload: { reps } }) => ({
    ...state,
    bulkReps: reps,
    importId: null,
    importStatus: 'completed',
    isImportsTableHidden: false,
    isPending: false,
    publisherUsers: updatedRepsFromImport({
      newReps: reps,
      previousReps: state.publisherUsers,
    })
  })
}

const publisherUsersImport = {
  importUsersFailure: state => ({ ...state, isPending: false }),
  importUsersStart: state => ({ ...state, isPending: true }),
  importUsersSuccess: (state, { payload: { importId, status } }) => ({
    ...state, importId, importStatus: status
  })
}

const getPublisherUsers = {
  getPublisherUsersFailure: state => ({ ...state, isPending: false }),
  getPublisherUsersStart: state => ({ ...state, isPending: true }),
  getPublisherUsersSuccess: (state, { payload: { publisherUsers } }) => ({
    ...state, isPending: false, publisherUsers
  })
}

const clearNewRecord = state => ({
  ...state, isEditing: false, newRecord: null
})

const changeNewRecord = (state, { payload: { target: { name, value } } }) => ({
  ...state, newRecord: { ...state.newRecord, [name]: value }
})

const showGrantAccessForm = state => ({
  ...state, newRecord: newRecordTemplate
})

const hideImportsDataTable = state => ({
  ...state, isImportsTableHidden: true
})

export const reducers = {
  ...removeUser,
  ...grantAccess,
  ...getRows,
  ...publisherUsersImport,
  ...getPublisherUsers,
  changeNewRecord,
  clearNewRecord,
  hideImportsDataTable,
  showGrantAccessForm
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
