import PropTypes from 'prop-types'

import Spinner from '@/components/Spinner'

const ButtonWithSpinner = ({ className, theme }) => {
  const spinnerTheme = theme ? theme : 'sk-spinner-three-bounce-white'

  return (
    <button
      className={className}
      type='button'
    >
      <Spinner
        {...{ styles: `sk-spinner sk-spinner-three-bounce ${spinnerTheme}` }}
      />
    </button>
  )
}

export default ButtonWithSpinner

ButtonWithSpinner.propTypes = {
  className: PropTypes.string.isRequired,
  theme: PropTypes.string
}
