import GoogleTagManager from '@redux-beacon/google-tag-manager'
import logger from '@redux-beacon/logger' // optional
import { createMiddleware } from 'redux-beacon'

import { eventsMap } from './eventsMapper'

const gtm = GoogleTagManager()

let gtmMiddleware = createMiddleware(eventsMap, gtm)

const actAsProd = process.env.RAILS_ENV === `production`
  || process.env.NODE_ENV === `production`

if (!actAsProd) {
  gtmMiddleware = createMiddleware(eventsMap, gtm, { logger })
}

export default gtmMiddleware
