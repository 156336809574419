import { useSelector } from 'react-redux'

import {
  expressCheckoutSavingsSelector,
  hasExpressCheckoutSavingsSelector,
} from '@/selectors'
import { translate } from '@/utils/translations'

const Savings = () => {
  const hasSavings = useSelector(hasExpressCheckoutSavingsSelector)
  const savings = useSelector(expressCheckoutSavingsSelector)

  return (
    <If condition={hasSavings}>
      <li>
        <p>{`${translate('carts.savings')}:`}</p>
        <p>{savings}</p>
      </li>
    </If>
  )
}

export default Savings
