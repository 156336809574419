import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'

import AddToCart from '@/components/PDP/PricingPanel/AddToCartButton/AddToCart'
import Spinner from '@/components/Spinner'
import { create } from '@/reducers/lineItems/asyncActions'
import { actions as miniCartActions } from '@/reducers/miniCart'
import {
  addToCartButtonTextSelector,
  assetLineItemSelector,
  isMiniCartEnabledSelector,
  quantitySelector
} from '@/selectors'

import AddedItemToMiniCartButton from './AddedItemToMiniCartButton'
const spinnerBtnClass = [
  'sk-spinner',
  'sk-spinner-three-bounce-white'
].join(' ')

const MiniCartAddToCartButton = ({ onClick }) => {
  const dispatch = useDispatch()
  const quantity = useSelector(quantitySelector)
  const isAddingItem = useSelector(state => state.lineItems.status.creating)
  const id = useSelector(state => state.asset.selectedVariant.id)
  const isMiniCartEnabled = useSelector(isMiniCartEnabledSelector)
  const isAlreadyInCart = useSelector(state => state.asset.inCart)
  const [hasAddedToCart, setHasAddedToCart] = useState(isAlreadyInCart)
  const hasCurrentAssetInMiniCart = useSelector(assetLineItemSelector)
  const addToCartButtonText = useSelector(addToCartButtonTextSelector)

  const immediatelyShowMiniCart = () => {
    if (!isMobile) {
      dispatch(miniCartActions.setIsShowing(true))
      setHasAddedToCart(true)
    }
  }

  useEffect(() => {
    setHasAddedToCart(hasCurrentAssetInMiniCart)
  }, [hasCurrentAssetInMiniCart])

  const addToCart = event => {
    event.preventDefault()

    if (hasAddedToCart) {
      dispatch(miniCartActions.setIsShowing(true))
    } else {
      dispatch(create({
        component: 'AddToCartButton',
        quantity,
        variantId: id
      }))
      immediatelyShowMiniCart()
      onClick && onClick()
    }
  }

  const content = isAddingItem
    ? <Spinner styles={spinnerBtnClass} />
    : addToCartButtonText

  return (
    <Choose>
      <When condition={isMiniCartEnabled}>
        <AddedItemToMiniCartButton onClick={addToCart} />
      </When>
      <Otherwise>
        <AddToCart action={addToCart}>
          {content}
        </AddToCart>
      </Otherwise>
    </Choose>
  )
}

export default MiniCartAddToCartButton

MiniCartAddToCartButton.propTypes = { onClick: PropTypes.func }
