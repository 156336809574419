import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state,
  ...payload[SLICES.regionRecommendation]
})

const setIsRecommendingRegionGuide = (state, { payload: isShowing }) => ({
  ...state,
  isRecommendingRegionGuide: isShowing
})

export const reducers = { setIsRecommendingRegionGuide }

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
