import { useSelector } from 'react-redux'

import { translate } from '@/utils/translations'

const DialogText = () => {
  const parentConnectRegionId = useSelector(
    state => state.currentRegion.parentConnectRegionId
  )

  return (
    <>
      <p>
        {translate(`tooltips.${parentConnectRegionId}_only_warning_tooltip`)}
      </p>
    </>
  )
}

export default DialogText
