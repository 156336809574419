import CartItemVariant from './cartItemVariant'
import PricingPanelVariant from './pricingPanelVariant'

const Variant = { CartItemVariant, PricingPanelVariant }

export default {
  createVariant(type, attributes) {
    const VariantType = Variant[type]

    return new VariantType(attributes)
  }
}
