import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { expressCheckoutSubtotalSelector } from '@/selectors'
import { translate } from '@/utils/translations'

const Subtotal = ({ isBold }) => {

  const subtotal = useSelector(expressCheckoutSubtotalSelector)
  const title = `${translate('carts.subtotal')}:`
  const className = isBold ? 'order_summary_subtotal' : null

  return (
    <li>
      <p className={className}>
        {title}
      </p>
      <p className={className}>
        {subtotal}
      </p>
    </li>
  )
}

export default Subtotal

Subtotal.propTypes = { isBold: PropTypes.bool }
