/*eslint-disable react/no-danger*/
import PropTypes from 'prop-types'

import Dialog from '@/components/Dialog'
import { translate } from '@/utils/translations'

const CarbonNeutralModal = ({ isShowing, onChangeShow }) => {

  const key = 'pages.store_home.carbon_neutrality'

  const kinds = ['computer', 'tree', 'car']

  return (
    <Dialog
      heading={translate(`${key}.planet_smart`)}
      id='carbon-neutrality-modal'
      isShowing={isShowing}
      onChangeShow={onChangeShow}
    >
      <div className='content'>
        <div className='planet-smart' />
        <p className='u-push--bottom'>{translate(`${key}.intro`)}</p>
        <div className='d-flex flex-column'>
          {
            kinds.map(kind => {
              return (
                <div className='block' key={kind}>
                  <div className='icon'>
                    <div className={`icon-${kind}`} />
                  </div>
                  <p>{translate(`${key}.${kind}`)}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </Dialog>
  )
}

export default CarbonNeutralModal

CarbonNeutralModal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onChangeShow: PropTypes.func.isRequired
}
