import { PERPETUAL } from '@/constants/durations'

class PricingPanelVariant {
  constructor({
    id,
    isOnlineResource,
    isOnlineResourceBundle,
    localePrice,
    selectedVariantId,
    variant,
    translations
  }) {
    const {
      purchase_buy_etextbook,
      purchase_purchase_item
    } = translations

    const { duration } = variant

    const isSelected = id === selectedVariantId

    const isPerpetual = duration === PERPETUAL

    const isOnlineResourceOrBundle = isOnlineResource || isOnlineResourceBundle

    const durationExpirationText = () => {
      if (isPerpetual) {
        if(isOnlineResourceOrBundle) {
          return purchase_purchase_item
        } else {
          return purchase_buy_etextbook
        }
      }
      else {
        return duration
      }
    }

    this.id = id,
    this.studentPrice = localePrice.formattedAmount
    this.durationExpirationText = durationExpirationText()
    this.className = `variant${isSelected ? ' selected' : ''}`,
    this.fullVariant = variant
    this.isSelected = isSelected
  }
}

export default PricingPanelVariant
