import PropTypes from 'prop-types'

const BulkStorePurchaseOptionsLink = ({
  bulkStoreUrl,
  isShowingBulkLink,
  translations: { purchase_bulk_purchase_options }
}) => (
  <If condition={isShowingBulkLink}>
    <div className='bulk-store-pdp-link'>
      <a
        className='bulk-purchase-options'
        href={bulkStoreUrl}
      >
        {purchase_bulk_purchase_options}
      </a>
    </div>
  </If>
)

export default BulkStorePurchaseOptionsLink

BulkStorePurchaseOptionsLink.propTypes = {
  bulkStoreUrl: PropTypes.string,
  isShowingBulkLink: PropTypes.bool.isRequired,
  translations: PropTypes.shape({
    purchase_bulk_purchase_options: PropTypes.string.isRequired
  })
}
