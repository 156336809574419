export const scrollToStep = stepId => {
  const scrollToElement = document.getElementById(stepId)
  const headerHeightWithMargin = 124
  const behavior = 'smooth'

  if (scrollToElement) {
    window.scrollTo(
      {
        behavior: behavior,
        top: scrollToElement.offsetTop - headerHeightWithMargin,
      }
    )
    scrollToElement.focus()
  }
}

export const setFocusToStepHeader = stepHeaderId => {
  const stepHeaderElement = document.getElementById(stepHeaderId)

  if (stepHeaderElement) stepHeaderElement.focus()
}
