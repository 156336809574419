import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialStatuses = { error: null, fetching: false, }

export const initialState = {
  request: {},
  status: initialStatuses,
  url: ''
}

const bulkRedemptionGuidance = createSlice({
  extraReducers,
  initialState,
  name: SLICES.bulkRedemptionGuidance,
  reducers
})

export const { actions } = bulkRedemptionGuidance

export default bulkRedemptionGuidance
