import { useSelector } from 'react-redux'

import Expiration from '@/components/CartItemExpiration'
import variantFactory from '@/utils/factory/variant/variantFactory'
import { translate } from '@/utils/translations'
const CART_ITEM_VARIANT_TYPE = 'CartItemVariant'
const LICENSE_DURATION_TYPE = 'LicenseDuration'

import licenseInformationFactory from
  '@/utils/factory/licenseInformation/licenseInformationFactory'

require('./index.scss')

const TitleDetails = () => {
  const title = useSelector(state => state.expressCheckout.asset.title)
  const subtitle = useSelector(state => state.expressCheckout.asset.subtitle)

  const selectedVariant = useSelector(state => state.asset.selectedVariant)

  const { duration } = selectedVariant

  const {
    isOnlineResource,
    isSubscription
  } = useSelector(state => state.asset)

  const imageURL = useSelector(
    state => state.expressCheckout.asset.coverImageUrl
  )

  const cartItemVariant = variantFactory.createVariant(
    CART_ITEM_VARIANT_TYPE, {
    daysLeftInLicense: 0,
    isOnlineResource,
    isSubscription,
    variant: selectedVariant
  })

  const licenseDuration = licenseInformationFactory.createLicense(
    LICENSE_DURATION_TYPE, {
    duration,
    isOnlineResource,
    isSubscription
  }
  )

  return (
    <div className='express_checkout__content'>
      <div className='express_checkout__content__wrap'>
        <img alt={title} src={imageURL} />
        <div className='express_checkout__content__wrap__details'>
          <p><strong>{title}</strong></p>
          <p>{subtitle}</p>
          <If condition={licenseDuration.isShowing}>
            <p>{translate('common.duration')}{':'} {licenseDuration.text}</p>
          </If>
          <Expiration {...{ cartItemVariant, }} />
        </div>
      </div>
    </div>
  )
}

export default TitleDetails
