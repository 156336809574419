/* eslint-disable react/no-danger */
require('../index.scss')
import PropTypes from 'prop-types'
import { createRef, useEffect } from 'react'

import { PAGE_LEVEL_MESSAGE_TYPES } from '@/constants/notificationTypes'

const Message = ({ message, type }) => {
  const { success } = PAGE_LEVEL_MESSAGE_TYPES
  const icon = type === success ? 'checkmark-circle' : 'exclamation-circle'
  const classNames = ['page-level-message', type].join(' ')

  const messageRef = createRef()

  useEffect(() => {
    messageRef.current.focus()
  }, [])

  return (
    <div className={classNames} ref={messageRef} tabIndex='-1'>
      <img alt='' src={require(`/app/assets/images/${icon}.svg`)} />
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}

export default Message
Message.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    PAGE_LEVEL_MESSAGE_TYPES.error,
    PAGE_LEVEL_MESSAGE_TYPES.success
  ]).isRequired
}
