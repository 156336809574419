import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = []

const flashMessages = createSlice({
  extraReducers,
  initialState,
  name: SLICES.flashMessages,
  reducers
})

export const { actions } = flashMessages
export default flashMessages
