import { createSlice } from '@reduxjs/toolkit'

import { SLICES } from '@/reducers/utils/constants'

import { extraReducers, reducers } from './reducers'

const initialState = {
  isRecommendingRegionGuide: false,
  key: null,
}

const regionRecommendation = createSlice({
  extraReducers,
  initialState,
  name: SLICES.regionRecommendation,
  reducers
})

export const { actions } = regionRecommendation
export default regionRecommendation
