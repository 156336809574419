import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.expressCheckout]
})

const fetchExpressCheckout = {
  fetchExpressCheckoutFailure: (state, { payload }) => ({
    ...state,
    error: payload
  }),

  fetchExpressCheckoutStart: state => ({
    ...state,
    isLoading: true
  }),

  fetchExpressCheckoutSuccess: (state, { payload }) => ({
    ...state,
    asset: payload.asset,
    error: false,
    hasLoaded: true,
    isLoading: false,
    taxLabel: payload.taxLabel
  })
}

export const reducers = {
  ...fetchExpressCheckout
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
