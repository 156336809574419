import { useSelector } from 'react-redux'

import MiniCartAddToCartButton from '@/components/MiniCartAddToCartButton'
import {
  addToCartActionButtonClassNamesSelector,
  isShowingSalesRightsModalSelector
} from '@/selectors'

import SalesRightsWarningModal from '../SalesRightsWarningModal'

const AddToCartActionButton = () => {
  const classNames = useSelector(addToCartActionButtonClassNamesSelector)
  const isShowingModal = useSelector(isShowingSalesRightsModalSelector)

  return (
    <div className={classNames}>
      <Choose>
        <When condition={isShowingModal}>
          <SalesRightsWarningModal />
        </When>
        <Otherwise>
          <MiniCartAddToCartButton />
        </Otherwise>
      </Choose>
    </div>
  )
}

export default AddToCartActionButton
