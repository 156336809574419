import { combineReducers } from 'redux'

import accordion from './accordion'
import activeRegions from './activeRegions'
import activeSamplingRegions from './activeSamplingRegions'
import adminPermissions from './adminPermissions'
import adminRoleSwitcher from './adminRoleSwitcher'
import asset from './asset'
import billingAddress from './billingAddress'
import bulkRedemptionGuidance from './bulkRedemptionGuidance'
import cart from './cart'
import checkout from './checkout'
import currentLocale from './currentLocale'
import currentRegion from './currentRegion'
import dashboardMenu from './dashboardMenu'
import expressCheckout from './expressCheckout'
import featureFlags from './featureFlags'
import flashMessages from './flashMessages'
import ghostInspector from './ghostInspector'
import guestUsers from './guestUsers'
import institutions from './institutions'
import lineItems from './lineItems'
import miniCart from './miniCart'
import mobileMenu from './mobileMenu'
import onlineResourceModal from './onlineResourceModal'
import orderHistory from './orderHistory'
import promoCampaigns from './promoCampaigns'
import publisherUsers from './publisherUsers'
import recaptcha from './recaptcha'
import regionRecommendation from './regionRecommendation'
import relatedAssets from './relatedAssets'
import taxes from './taxes'
import user from './user'
import whiteLabelStoreDemo from './whiteLabelStoreDemo'

export default combineReducers({
  accordion: accordion.reducer,
  activeRegions: activeRegions.reducer,
  activeSamplingRegions: activeSamplingRegions.reducer,
  adminPermissions: adminPermissions.reducer,
  adminRoleSwitcher: adminRoleSwitcher.reducer,
  asset: asset.reducer,
  billingAddress: billingAddress.reducer,
  bulkRedemptionGuidance: bulkRedemptionGuidance.reducer,
  cart: cart.reducer,
  checkout: checkout.reducer,
  currentLocale: currentLocale.reducer,
  currentRegion: currentRegion.reducer,
  dashboardMenu: dashboardMenu.reducer,
  expressCheckout: expressCheckout.reducer,
  featureFlags: featureFlags.reducer,
  flashMessages: flashMessages.reducer,
  ghostInspector: ghostInspector.reducer,
  guestUsers: guestUsers.reducer,
  institutions: institutions.reducer,
  lineItems: lineItems.reducer,
  miniCart: miniCart.reducer,
  mobileMenu: mobileMenu.reducer,
  onlineResourceModal: onlineResourceModal.reducer,
  orderHistory: orderHistory.reducer,
  promoCampaigns: promoCampaigns.reducer,
  publisherUsers: publisherUsers.reducer,
  recaptcha: recaptcha.reducer,
  regionRecommendation: regionRecommendation.reducer,
  relatedAssets: relatedAssets.reducer,
  taxes: taxes.reducer,
  user: user.reducer,
  whiteLabelStoreDemo: whiteLabelStoreDemo.reducer
})
