import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state, ...payload[SLICES.whiteLabelStoreDemo]
})

const changeColor = (state, { payload }) => ({
  ...state,
  demoImage: payload
})

const changeArrowClass = (state, { payload }) =>({
  ...state,
  arrowImage: payload
})

export const reducers = { changeArrowClass, changeColor }
export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
