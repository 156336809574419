import { SHARED_ACTIONS, SLICES } from '@/reducers/utils/constants'

const initialLoad = (state, { payload }) => ({
  ...state,
  ...payload[SLICES.promoCampaigns]
})

const submitForm = {
  submitFormFailure: state => ({
    ...state,
    isSubmitting: false
  })
  ,
  submitFormStart: state => ({
    ...state,
    isSubmitting: true
  }),
  submitFormSuccess: state => ({
    ...state,
    isSubmitting: false,
  })
}

export const reducers = {
  ...submitForm
}

export const extraReducers = {
  [SHARED_ACTIONS.updateStateFromBackend]: initialLoad,
}
