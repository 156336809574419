import PropTypes from 'prop-types'

import ReadNow from '@/components/ReadNow'

import WatkinsReadNowInBookshelf from './watkins_read_now_in_bookshelf.svg'

const AssetPurchasedCard = ({
  readNowButtonProps: {
    isAcrobatiq,
    isBulkIntegrationEnabled,
    isBundle,
    isGuestReferenceUser,
    isOnlineResource,
    isShowingCustomerSupportUrl,
    isShowingExpirationStatus,
    readNowExpirationDays,
    readNowUrl,
    supportUrl,
    translations
  }
}) => {
  const {
    common_already_purchased_online_resource,
    common_already_purchased_online_resource_bundle,
    common_product_already_purchased
  } = translations

  const purchasedText = () => {
    if (isBundle) {
      return common_already_purchased_online_resource_bundle
    } else if (isOnlineResource) {
      return common_already_purchased_online_resource
    } else {
      return common_product_already_purchased
    }
  }

  return(
    <div className='asset-purchased card'>
      <Choose>
        <When condition={isBulkIntegrationEnabled}>
          <div className='asset-banner-purchased'>
            <p className='font-5 u-weight--normal'>
              {purchasedText()}
            </p>
          </div>
        </When>
        <Otherwise>
          <img
            alt=''
            className='watkins-read-bookshelf-image'
            src={WatkinsReadNowInBookshelf}
          />
        </Otherwise>
      </Choose>
      <ReadNow {...{
        isAcrobatiq,
        isBulkIntegrationEnabled,
        isBundle,
        isGuestReferenceUser,
        isOnlineResource,
        isShowingCustomerSupportUrl,
        isShowingExpirationStatus,
        readNowExpirationDays,
        readNowUrl,
        supportUrl,
        translations
      }}
      />
    </div>
  )
}

export default AssetPurchasedCard

AssetPurchasedCard.propTypes = {
  readNowButtonProps: PropTypes.shape({
    isAcrobatiq: PropTypes.bool.isRequired,
    isBulkIntegrationEnabled: PropTypes.bool.isRequired,
    isBundle: PropTypes.bool.isRequired,
    isGuestReferenceUser: PropTypes.bool.isRequired,
    isOnlineResource: PropTypes.bool.isRequired,
    isShowingCustomerSupportUrl: PropTypes.bool.isRequired,
    isShowingExpirationStatus: PropTypes.bool.isRequired,
    readNowExpirationDays: PropTypes.string.isRequired,
    readNowUrl: PropTypes.string.isRequired,
    supportUrl: PropTypes.string.isRequired,
    translations: PropTypes.shape({
      common_access_code_send_by_email: PropTypes.string.isRequired,
      common_already_purchased_online_resource: PropTypes.string.isRequired,
      common_already_purchased_online_resource_bundle:
        PropTypes.string.isRequired,
      common_customer_support: PropTypes.string.isRequired,
      common_product_already_purchased: PropTypes.string.isRequired,
      common_read_in_bookshelf: PropTypes.string.isRequired
    })
  })
}
