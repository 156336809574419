/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PageLevelMessage from '@/components/PageLevelMessage'
import { actions as flashMessageActions } from '@/reducers/flashMessages'
import {
  expressCheckoutSavedPaymentCardInfoSelector,
  expressCheckoutSavedPaymentExpirationTextSelector
} from '@/selectors'
import { translate } from '@/utils/translations'

const SavedPaymentInformation = () => {
  const dispatch = useDispatch()
  const expirationText = useSelector(
    expressCheckoutSavedPaymentExpirationTextSelector
  )
  const cardInformationText = useSelector(
    expressCheckoutSavedPaymentCardInfoSelector
  )

  const userEmail = useSelector(state => state.expressCheckout.user.email)

  useEffect(() => {
    dispatch(
      flashMessageActions.handleErrorAsPageLevelMessage(
        translate('errors.express_checkout.expired_credit_card')
      ))
  }, [])

  return (
    <div className='express_checkout__saved_payment'>
      <hr />
      <div>
        <div className='d-flex justify-content-between'>
          <p>
            <strong>
              {translate('carts.express_checkout.contact_information')}
            </strong>
          </p>
          <a href='#'>{translate('common.change')}</a>
        </div>
        <p>{userEmail}</p>
      </div>
      <hr />
      <div>
        <div className='d-flex justify-content-between'>
          <p>
            <strong>
              {translate('carts.express_checkout.payment_type')}
            </strong>
          </p>
          <a href='#'>{translate('common.change')}</a>
        </div>
        <p>{translate('checkout.payment_type.stripe')}</p>
      </div>
      <hr />
      <div>
        <div className='d-flex justify-content-between'>
          <p>
            <strong>
              {translate('carts.express_checkout.billing_information')}
            </strong>
          </p>
          <a href='#'>{translate('common.change')}</a>
        </div>
        <p>
          {'Dwayne Carter Jr.'} <br />
          {'4562 Front Street'} <br />
          {'New Orleans, LA'} <br />
          {'875124 USA'}
        </p>
      </div>
      <hr />
      <div>
        <div className='d-flex justify-content-between'>
          <p>
            <strong>
              {translate('carts.express_checkout.payment_information')}
            </strong>
          </p>
          <a href='#'>{translate('common.change')}</a>
        </div>
        <p>{cardInformationText}</p>
        <p>{expirationText}</p>

        <PageLevelMessage />
      </div>
      <hr />
    </div>
  )
}

export default SavedPaymentInformation
